/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { EsgAnalysisPortfolioFragment } from '@predium/client-graphql';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBoxWithLazyLoading, { SearchItemType } from '../../../components/search/SearchBoxWithLazyLoading';
import { PermissionType } from '../../../contexts/PermissionContext';
import { GET_PORTFOLIOS_BY_IDS, GET_PORTFOLIOS_BY_SEARCHED_STRING } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePermissions from '../../../hooks/usePermissions';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import NavigationSelectorButton from './NavigationSelectorButton';

export type AutoCompleteValue = {
  value: number | string;
  label: string;
};

type Props = {
  filtersComponent: ReactNode;
};

export const ALL_PORTFOLIOS = 'all_portfolios';

export default function PortfolioSelector({ filtersComponent }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const ref = useRef<HTMLDivElement>(null);
  const limit = 10; // limit for pagination
  const { checkPortfolioPermission } = usePermissions();

  const [portfolios, setPortfolios] = useState<SearchItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // For debounced query trigger
  const [filteredPortfolios, setFilteredPortfolios] = useState<SearchItemType[]>([]);

  const { filters, resetFilter, setFilter } = useEsgAnalysisFilters();
  const portfolioIds = filters.portfolioIds;

  const { loading, fetchMore, refetch } = useQuery(GET_PORTFOLIOS_BY_SEARCHED_STRING, {
    variables: { offset: 0, limit, text: `%${debouncedSearchTerm.trim()}%` },
    skip: !showSearchBox, // Skip if no debounced search term
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.portfolio) {
        setPortfolios(
          data.portfolio
            .filter((portfolio) => checkPortfolioPermission(portfolio.id, PermissionType.READ))
            .map(mapToPortfolioSelectOption),
        );
        setHasMore(data.portfolio.length === limit); // Check if we have more data
      }
    },
  });

  const { data } = useQuery(GET_PORTFOLIOS_BY_IDS, {
    variables: {
      // @ts-ignore
      ids: portfolioIds,
    },
    skip: showSearchBox || !portfolioIds || portfolioIds.length === 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSearchBox(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  /**
   * Creates each option for the portfolio select
   */
  const mapToPortfolioSelectOption = (portfolio: EsgAnalysisPortfolioFragment): SearchItemType => {
    return {
      value: portfolio.id,
      label: portfolio.name ?? t('EsgAnalysisFilterBarPortfolio_NoName_WithId', { portfolioId: portfolio.id }),
    };
  };

  const getPortfoliosFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return portfolios.filter((portfolio: SearchItemType) => ids.includes(portfolio.value));
  };

  const onPortfolioSelected = (selectedPortfolio: SearchItemType) => {
    trackEvent('PORTFOLIO_SELECTED', {
      portfolio_id: selectedPortfolio.value,
    });

    setFilter(EsgAnalysisFiltersKeys.portfolioIds, selectedPortfolio.value);
    resetFilter(EsgAnalysisFiltersKeys.buildingIds);
  };

  const loadMore = async (newOffset: number, newDebouncedSearchTerm: string) => {
    if (!hasMore) return;

    const result = await fetchMore({
      variables: {
        offset: newOffset,
        limit,
        text: `%${newDebouncedSearchTerm.trim()}%`,
      },
    });

    // Check if we received more data
    if (result.data.portfolio.length < limit) {
      setHasMore(false); // No more data
    } else {
      setHasMore(true); // More data exists
    }

    // Append new buildings
    setPortfolios((prev) => [...prev, ...result.data.portfolio.map(mapToPortfolioSelectOption)]);
  };

  useEffect(() => {
    if (showSearchBox) {
      setFilteredPortfolios(getPortfoliosFromIds(portfolioIds) ?? []);
    } else {
      setFilteredPortfolios(data?.portfolio ? data?.portfolio.map(mapToPortfolioSelectOption) : []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios, data, portfolioIds, showSearchBox]);

  const count = (filteredPortfolios && filteredPortfolios.length) ?? 0;

  const selectorLabel =
    count > 1
      ? `${count} ${t('General_Portfolio', {
          count: count,
        })}`
      : filteredPortfolios[0]?.label ?? t('General_AllPortfolios');

  const handleTypographyClick = () => {
    setShowSearchBox(!showSearchBox);
    if (portfolios.length === 0) {
      refetch(); // Fetch initial data if buildings are empty
    }
  };

  const handleClear = () => {
    resetFilter(EsgAnalysisFiltersKeys.portfolioIds);
    resetFilter(EsgAnalysisFiltersKeys.buildingIds);
    trackEvent('PORTFOLIO_SELECTED', {
      portfolio_id: ALL_PORTFOLIOS,
    });
  };

  return (
    <Box ref={ref} ml={1}>
      <Stack direction="row" alignItems={'center'}>
        <NavigationSelectorButton
          title={selectorLabel}
          onClick={handleTypographyClick}
          isSelected={showSearchBox}
          tooltipText={t('General_SelectPortfolio')}
        />
        {filtersComponent && <Box>{filtersComponent}</Box>}
      </Stack>

      {showSearchBox && (
        <SearchBoxWithLazyLoading
          refetch={refetch}
          onItemSelected={onPortfolioSelected}
          selectedItems={filteredPortfolios}
          loading={loading}
          listItems={portfolios}
          loadMore={loadMore}
          hasMore={hasMore}
          setHasMore={setHasMore}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          debouncedSearchTerm={debouncedSearchTerm}
          noDataText={t('EsgAnalysisFilterBarPortfolio_NoPortfoliosFound')}
          placeholder={t('General_SearchPortfolioAction')}
          handleClear={handleClear}
          isMultiSelect
          showClearButton={count > 0 || filteredPortfolios.length > 0}
        />
      )}
    </Box>
  );
}
