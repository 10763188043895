import { country_enum } from '@predium/enums';

export const SUPPORTED_YEARS = [2020, 2021, 2022, 2023];

export function getRenewableDistrictHeatingEmissionFactor(country: country_enum): number {
  switch (country) {
    case country_enum.DE:
      return 0.05613427417198402;
    case country_enum.AT:
      return 0.02835889176495527;
    case country_enum.PL:
      return 0.07631790789848127;
    default: {
      const exhaustiveCheck: never = country;
      throw new Error(`Unhandled country ${country}: ${exhaustiveCheck}`);
    }
  }
}
