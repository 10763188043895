/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { area_system_type_enum, area_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateSubBuildingClassEnum_dynamic, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getEnergyReferenceAreaSum } from '@predium/utils';
import find from 'lodash/find';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommercialIcon from '../../../../../assets/Icons/CommercialIcon';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import PreDialog, { PreDialogBody, PreDialogTitle } from '../../../../../components/presentations/PreDialog/PreDialog';
import SquareMeterRow from '../Components/SquareMeterRow';
import System from '../Components/System';
import EBFForm, { EBFFormSubmitValues } from '../forms/EBFForm';
import { useArea } from '../hooks';

const CLASS_ICONS = {
  [sub_building_class_enum.COMMERCIAL]: <CommercialIcon sx={{ color: 'grey.600' }} />,
  [sub_building_class_enum.RESIDENTIAL]: <Iconify color="grey.600" icon="mdi:home-outline" height={24} width={24} />,
};

const EnergyReferenceAreas = () => {
  const { getPropsForEditableArea, updateArea, fields, addArea, removeArea } = useArea();

  const [addEBF, setAddEBF] = useState(false);
  const [editEBFId, setEditEBFId] = useState<string | null>(null);

  const { t } = useTranslation();

  const ebf = fields.filter((area) => area.area_type_id === area_type_enum.EBF && !area.delete);
  const ebfSum = getEnergyReferenceAreaSum(ebf);

  const handleAddEBF = (values: EBFFormSubmitValues) => {
    setAddEBF(false);
    addArea({
      ...values,
      area_system_id: area_system_type_enum.PREDIUM_2024,
      area_type_id: area_type_enum.EBF,
      name: area_type_enum.EBF,
      is_rented: false,
      description: '',
    });
  };

  const handleEditEBF = (values: EBFFormSubmitValues) => {
    if (editEBFId === null) {
      throw new Error('editEBFId is null');
    }

    const currentEBF = find(ebf, { uid: editEBFId });

    if (!currentEBF) {
      throw new Error('currentEBF is null');
    }

    setEditEBFId(null);

    updateArea(editEBFId, values);
  };

  const handleRemoveEBF = (areaUid: string) => () => {
    removeArea(areaUid);
  };

  return (
    <>
      <System
        title={t('DataCollectionAreas_EnergyReferenceArea')}
        subtitle={t('DataCollectionAreas_EnergyReferenceAreaSubtitle')}
        onAddAreaClick={() => setAddEBF(true)}
        areaSum={ebfSum}
        showAreaSum={ebf.length > 1}
        listAreaType={area_type_enum.EBF}
        areas={
          <Stack spacing={1}>
            {ebf.map((area) => (
              <SquareMeterRow
                key={area.id}
                {...getPropsForEditableArea(area.uid)}
                options={{
                  hoverEffectSupport: true,
                  displayErrorInline: false,
                  hideEquationError: ebf.length > 1,
                }}
                icon={CLASS_ICONS[area.class_of_use_id]}
                labels={{
                  title: translateSubBuildingClassEnum_dynamic(area.class_of_use_id, t),
                  subtitle: translateTypeOfUseEnum_dynamic(area.type_of_use_id, t),
                }}
                titleProps={{ variant: 'subtitle2' }}
                actions={
                  <>
                    <MenuItem onClick={() => setEditEBFId(area.uid)}>
                      <Iconify icon={'fa-regular:edit'} />
                      {t('General_Edit')}
                    </MenuItem>
                    <Tooltip title={ebf.length === 1 ? t('DataCollectionAreas_LastItemDeleteTooltip') : ''}>
                      <Box component="span">
                        <MenuItem
                          sx={{ color: 'error.main' }}
                          disabled={ebf.length === 1}
                          onClick={handleRemoveEBF(area.uid)}
                        >
                          <Iconify icon={ICONS.TRASH} />
                          {t('General_Delete')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </Stack>
        }
      />
      <PreDialog open={addEBF} onClose={() => setAddEBF(false)} fullWidth type="definedByChildren">
        {addEBF && (
          <PreDialogBody
            dialogtitle={
              <PreDialogTitle
                icon="mdi:account-circle-outline"
                title={t('DataCollectionAreas_AddEnergyReferenceAreaTitle')}
              />
            }
            content={<EBFForm onSubmit={handleAddEBF} />}
            actions={
              <>
                <Button variant="outlined" onClick={() => setAddEBF(false)}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="ebf-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
      <PreDialog open={Boolean(editEBFId)} onClose={() => setEditEBFId(null)} fullWidth type="definedByChildren">
        {editEBFId !== null && (
          <PreDialogBody
            dialogtitle={
              <PreDialogTitle
                icon="mdi:account-circle-outline"
                title={t('DataCollectionAreas_EditEnergyReferenceAreaTitle')}
              />
            }
            content={
              <Stack spacing={2}>
                <Typography>{t('DataCollectionAreas_EditEnergyReferenceAreaDescription')}</Typography>
                <EBFForm defaultValue={find(ebf, { uid: editEBFId })} onSubmit={handleEditEBF} />
              </Stack>
            }
            actions={
              <>
                <Button variant="outlined" onClick={() => setEditEBFId(null)}>
                  {t('General_Cancel')}
                </Button>
                <Button type="submit" variant="contained" form="ebf-form">
                  {t('General_Accept')}
                </Button>
              </>
            }
          />
        )}
      </PreDialog>
    </>
  );
};

export default EnergyReferenceAreas;
