/** Generated enum from hasura. Look at the corresponding TS enum for description. */
export enum MultiNestedTypeEnum {
  every = 'every',
  none = 'none',
  some = 'some',
}

/** Generated enum from hasura. Look at the corresponding TS enum for description. */
export enum OperatorEnum {
  _eq = '_eq',
  _gt = '_gt',
  _gte = '_gte',
  _lt = '_lt',
  _lte = '_lte',
  _neq = '_neq',
}

/** Generated enum from hasura. Look at the corresponding TS enum for description. */
export enum OperatorInEnum {
  _in = '_in',
  _nin = '_nin',
}

/** unique or primary key constraints on table "action" */
export enum action_constraint {
  /** unique or primary key constraint on columns "id" */
  action_pkey = 'action_pkey',
}

/** unique or primary key constraints on table "action_plan" */
export enum action_plan_constraint {
  /** unique or primary key constraint on columns "id" */
  action_plan_pkey = 'action_plan_pkey',
}

/** select columns of table "action_plan" */
export enum action_plan_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  scenario_id = 'scenario_id',
}

/** update columns of table "action_plan" */
export enum action_plan_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  scenario_id = 'scenario_id',
}

/** select columns of table "action" */
export enum action_select_column {
  /** column name */
  action_plan_id = 'action_plan_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  implementation_from = 'implementation_from',
  /** column name */
  implementation_to = 'implementation_to',
  /** column name */
  order = 'order',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "action_subsidy" */
export enum action_subsidy_constraint {
  /** unique or primary key constraint on columns "id" */
  action_subsidy_pkey = 'action_subsidy_pkey',
}

/** select columns of table "action_subsidy" */
export enum action_subsidy_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  subsidy_id = 'subsidy_id',
  /** column name */
  type = 'type',
  /** column name */
  value = 'value',
}

/** update columns of table "action_subsidy" */
export enum action_subsidy_update_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  subsidy_id = 'subsidy_id',
  /** column name */
  type = 'type',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "action_subsidy_value_type" */
export enum action_subsidy_value_type_constraint {
  /** unique or primary key constraint on columns "id" */
  action_subsidy_value_type_pkey = 'action_subsidy_value_type_pkey',
}

export enum action_subsidy_value_type_enum {
  absolute = 'absolute',
  percentage = 'percentage',
}

/** select columns of table "action_subsidy_value_type" */
export enum action_subsidy_value_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "action_subsidy_value_type" */
export enum action_subsidy_value_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "action" */
export enum action_update_column {
  /** column name */
  action_plan_id = 'action_plan_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  implementation_from = 'implementation_from',
  /** column name */
  implementation_to = 'implementation_to',
  /** column name */
  order = 'order',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "address" */
export enum address_constraint {
  /** unique or primary key constraint on columns "id" */
  address_pkey = 'address_pkey',
}

/** select columns of table "address" */
export enum address_select_column {
  /** column name */
  appartment = 'appartment',
  /** column name */
  city = 'city',
  /** column name */
  climate_region_id = 'climate_region_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  house_number = 'house_number',
  /** column name */
  id = 'id',
  /** column name */
  latitude = 'latitude',
  /** column name */
  longitude = 'longitude',
  /** column name */
  mapbox_bearing = 'mapbox_bearing',
  /** column name */
  mapbox_building_ids = 'mapbox_building_ids',
  /** column name */
  mapbox_pitch = 'mapbox_pitch',
  /** column name */
  mapbox_zoom = 'mapbox_zoom',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  street = 'street',
}

/** update columns of table "address" */
export enum address_update_column {
  /** column name */
  appartment = 'appartment',
  /** column name */
  city = 'city',
  /** column name */
  climate_region_id = 'climate_region_id',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  house_number = 'house_number',
  /** column name */
  id = 'id',
  /** column name */
  latitude = 'latitude',
  /** column name */
  longitude = 'longitude',
  /** column name */
  mapbox_bearing = 'mapbox_bearing',
  /** column name */
  mapbox_building_ids = 'mapbox_building_ids',
  /** column name */
  mapbox_pitch = 'mapbox_pitch',
  /** column name */
  mapbox_zoom = 'mapbox_zoom',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  street = 'street',
}

/** unique or primary key constraints on table "api_user" */
export enum api_user_constraint {
  /** unique or primary key constraint on columns "auth0_client_id" */
  api_user_auth0_client_id_key = 'api_user_auth0_client_id_key',
  /** unique or primary key constraint on columns "id" */
  api_user_pkey = 'api_user_pkey',
}

/** select columns of table "api_user" */
export enum api_user_select_column {
  /** column name */
  auth0_client_id = 'auth0_client_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  role_id = 'role_id',
}

/** update columns of table "api_user" */
export enum api_user_update_column {
  /** column name */
  auth0_client_id = 'auth0_client_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  role_id = 'role_id',
}

/** unique or primary key constraints on table "area" */
export enum area_constraint {
  /** unique or primary key constraint on columns "id" */
  area_pkey = 'area_pkey',
}

/** select columns of table "area" */
export enum area_select_column {
  /** column name */
  area_system_id = 'area_system_id',
  /** column name */
  area_type_id = 'area_type_id',
  /** column name */
  building_id = 'building_id',
  /** column name */
  can_be_rented = 'can_be_rented',
  /** column name */
  class_of_use_id = 'class_of_use_id',
  /** column name */
  data_source_id = 'data_source_id',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  is_rented = 'is_rented',
  /** column name */
  name = 'name',
  /** column name */
  parent_id = 'parent_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_avg_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_bool_and_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  can_be_rented = 'can_be_rented',
  /** column name */
  is_rented = 'is_rented',
}

/** select "area_aggregate_bool_exp_bool_or_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  can_be_rented = 'can_be_rented',
  /** column name */
  is_rented = 'is_rented',
}

/** select "area_aggregate_bool_exp_corr_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_max_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_min_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_sum_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  value = 'value',
}

/** select "area_aggregate_bool_exp_var_samp_arguments_columns" columns of table "area" */
export enum area_select_column_area_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "area_system_type" */
export enum area_system_type_constraint {
  /** unique or primary key constraint on columns "id" */
  area_system_type_pkey = 'area_system_type_pkey',
}

export enum area_system_type_enum {
  PREDIUM_2024 = 'PREDIUM_2024',
}

/** select columns of table "area_system_type" */
export enum area_system_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "area_system_type" */
export enum area_system_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "area_type" */
export enum area_type_constraint {
  /** unique or primary key constraint on columns "id" */
  area_type_pkey = 'area_type_pkey',
}

export enum area_type_enum {
  AF = 'AF',
  BGF = 'BGF',
  EBF = 'EBF',
  IPMS_2 = 'IPMS_2',
  MF = 'MF',
  NGF = 'NGF',
}

/** select columns of table "area_type" */
export enum area_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "area_type" */
export enum area_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "area" */
export enum area_update_column {
  /** column name */
  area_system_id = 'area_system_id',
  /** column name */
  area_type_id = 'area_type_id',
  /** column name */
  building_id = 'building_id',
  /** column name */
  can_be_rented = 'can_be_rented',
  /** column name */
  class_of_use_id = 'class_of_use_id',
  /** column name */
  data_source_id = 'data_source_id',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  is_rented = 'is_rented',
  /** column name */
  name = 'name',
  /** column name */
  parent_id = 'parent_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "async_job" */
export enum async_job_constraint {
  /** unique or primary key constraint on columns "id" */
  async_job_pkey = 'async_job_pkey',
}

/** select columns of table "async_job" */
export enum async_job_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  entity_id = 'entity_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  status_id = 'status_id',
  /** column name */
  title = 'title',
  /** column name */
  total_jobs_created = 'total_jobs_created',
  /** column name */
  total_jobs_finished = 'total_jobs_finished',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
}

/** unique or primary key constraints on table "async_job_status_type" */
export enum async_job_status_type_constraint {
  /** unique or primary key constraint on columns "id" */
  async_job_status_type_pkey = 'async_job_status_type_pkey',
}

export enum async_job_status_type_enum {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

/** select columns of table "async_job_status_type" */
export enum async_job_status_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "async_job_status_type" */
export enum async_job_status_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "async_job" */
export enum async_job_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  entity_id = 'entity_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  status_id = 'status_id',
  /** column name */
  title = 'title',
  /** column name */
  total_jobs_created = 'total_jobs_created',
  /** column name */
  total_jobs_finished = 'total_jobs_finished',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
}

/** unique or primary key constraints on table "building" */
export enum building_constraint {
  /** unique or primary key constraint on columns "id" */
  building_pkey = 'building_pkey',
}

/** unique or primary key constraints on table "building_crrem_config" */
export enum building_crrem_config_constraint {
  /** unique or primary key constraint on columns "id" */
  building_crrem_config_pkey = 'building_crrem_config_pkey',
}

/** select columns of table "building_crrem_config" */
export enum building_crrem_config_select_column {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
}

/** update columns of table "building_crrem_config" */
export enum building_crrem_config_update_column {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "building_model" */
export enum building_model_constraint {
  /** unique or primary key constraint on columns "id" */
  building_model_pkey = 'building_model_pkey',
}

/** select columns of table "building_model" */
export enum building_model_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  action_planning_available = 'action_planning_available',
  /** column name */
  air_change_rate_by_infiltration = 'air_change_rate_by_infiltration',
  /** column name */
  annual_heating_days = 'annual_heating_days',
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_scaled_deviation = 'energy_scaled_deviation',
  /** column name */
  external_temperature = 'external_temperature',
  /** column name */
  heating_demand = 'heating_demand',
  /** column name */
  heating_demand_scaled_deviation = 'heating_demand_scaled_deviation',
  /** column name */
  id = 'id',
  /** column name */
  internal_temperature = 'internal_temperature',
  /** column name */
  processing = 'processing',
  /** column name */
  reference_building_id = 'reference_building_id',
  /** column name */
  thermal_bridging = 'thermal_bridging',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_to = 'valid_to',
}

/** select "building_model_aggregate_bool_exp_bool_and_arguments_columns" columns of table "building_model" */
export enum building_model_select_column_building_model_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  action_planning_available = 'action_planning_available',
  /** column name */
  processing = 'processing',
}

/** select "building_model_aggregate_bool_exp_bool_or_arguments_columns" columns of table "building_model" */
export enum building_model_select_column_building_model_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  action_planning_available = 'action_planning_available',
  /** column name */
  processing = 'processing',
}

/** update columns of table "building_model" */
export enum building_model_update_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  action_planning_available = 'action_planning_available',
  /** column name */
  air_change_rate_by_infiltration = 'air_change_rate_by_infiltration',
  /** column name */
  annual_heating_days = 'annual_heating_days',
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_scaled_deviation = 'energy_scaled_deviation',
  /** column name */
  external_temperature = 'external_temperature',
  /** column name */
  heating_demand = 'heating_demand',
  /** column name */
  heating_demand_scaled_deviation = 'heating_demand_scaled_deviation',
  /** column name */
  id = 'id',
  /** column name */
  internal_temperature = 'internal_temperature',
  /** column name */
  processing = 'processing',
  /** column name */
  reference_building_id = 'reference_building_id',
  /** column name */
  thermal_bridging = 'thermal_bridging',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_to = 'valid_to',
}

/** select columns of table "building" */
export enum building_select_column {
  /** column name */
  active_building_model_id = 'active_building_model_id',
  /** column name */
  address_id = 'address_id',
  /** column name */
  building_state_id = 'building_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_external_identifier = 'customer_external_identifier',
  /** column name */
  economic_unit_id = 'economic_unit_id',
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  id = 'id',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
}

/** select "building_aggregate_bool_exp_bool_and_arguments_columns" columns of table "building" */
export enum building_select_column_building_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
}

/** select "building_aggregate_bool_exp_bool_or_arguments_columns" columns of table "building" */
export enum building_select_column_building_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
}

/** unique or primary key constraints on table "building_state" */
export enum building_state_constraint {
  /** unique or primary key constraint on columns "id" */
  building_state_pkey = 'building_state_pkey',
}

export enum building_state_enum {
  ACQUISITION = 'ACQUISITION',
  INVENTORY = 'INVENTORY',
}

/** select columns of table "building_state" */
export enum building_state_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "building_state" */
export enum building_state_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "building" */
export enum building_update_column {
  /** column name */
  active_building_model_id = 'active_building_model_id',
  /** column name */
  address_id = 'address_id',
  /** column name */
  building_state_id = 'building_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_external_identifier = 'customer_external_identifier',
  /** column name */
  economic_unit_id = 'economic_unit_id',
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  id = 'id',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
}

/** select columns of table "buildings_table_view" */
export enum buildings_table_view_select_column {
  /** column name */
  city = 'city',
  /** column name */
  co2_costs = 'co2_costs',
  /** column name */
  co2_costs_total = 'co2_costs_total',
  /** column name */
  co2_emissions = 'co2_emissions',
  /** column name */
  co2_emissions_total = 'co2_emissions_total',
  /** column name */
  country_id = 'country_id',
  /** column name */
  efficiency_class_id = 'efficiency_class_id',
  /** column name */
  efficiency_class_id_order = 'efficiency_class_id_order',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_final_total = 'energy_final_total',
  /** column name */
  energy_path_year = 'energy_path_year',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_primary_total = 'energy_primary_total',
  /** column name */
  eu_taxonomy_compliance_id = 'eu_taxonomy_compliance_id',
  /** column name */
  eu_taxonomy_compliance_id_order = 'eu_taxonomy_compliance_id_order',
  /** column name */
  id = 'id',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  street = 'street',
  /** column name */
  types_of_use = 'types_of_use',
}

/** unique or primary key constraints on table "climate_region" */
export enum climate_region_constraint {
  /** unique or primary key constraint on columns "id" */
  climate_region_pkey = 'climate_region_pkey',
}

export enum climate_region_enum {
  DE_EASTERN = 'DE_EASTERN',
  DE_NATIONAL = 'DE_NATIONAL',
}

/** select columns of table "climate_region" */
export enum climate_region_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "climate_region" */
export enum climate_region_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "climate_risk" */
export enum climate_risk_constraint {
  /** unique or primary key constraint on columns "id" */
  climate_risk_pkey = 'climate_risk_pkey',
}

/** select columns of table "climate_risk" */
export enum climate_risk_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  timespan = 'timespan',
  /** column name */
  type = 'type',
  /** column name */
  uncertainty = 'uncertainty',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "climate_risk_type" */
export enum climate_risk_type_constraint {
  /** unique or primary key constraint on columns "id" */
  climate_risk_type_pkey = 'climate_risk_type_pkey',
}

export enum climate_risk_type_enum {
  EARTHQUAKE = 'EARTHQUAKE',
  FOREST_FIRE = 'FOREST_FIRE',
  HAIL = 'HAIL',
  HEAT = 'HEAT',
  HEAVY_RAIN = 'HEAVY_RAIN',
  LIGHTNING_STRIKE = 'LIGHTNING_STRIKE',
  SNOW_LOAD = 'SNOW_LOAD',
  WINTER_STORM = 'WINTER_STORM',
}

/** select columns of table "climate_risk_type" */
export enum climate_risk_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "climate_risk_type" */
export enum climate_risk_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "climate_risk" */
export enum climate_risk_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  timespan = 'timespan',
  /** column name */
  type = 'type',
  /** column name */
  uncertainty = 'uncertainty',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "consumption" */
export enum consumption_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_pkey = 'consumption_pkey',
}

/** unique or primary key constraints on table "consumption_draft" */
export enum consumption_draft_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_draft_pkey = 'consumption_draft_pkey',
}

/** select columns of table "consumption_draft" */
export enum consumption_draft_select_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  area_id_deprecated = 'area_id_deprecated',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  cost = 'cost',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by_user_id = 'created_by_user_id',
  /** column name */
  display_unit_value = 'display_unit_value',
  /** column name */
  draft_status_deprecated = 'draft_status_deprecated',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  invoice_date_deprecated = 'invoice_date_deprecated',
  /** column name */
  invoice_draft_id = 'invoice_draft_id',
  /** column name */
  invoice_file_id_deprecated = 'invoice_file_id_deprecated',
  /** column name */
  provider = 'provider',
  /** column name */
  source_type_id = 'source_type_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  sub_type_id = 'sub_type_id',
  /** column name */
  to = 'to',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** update columns of table "consumption_draft" */
export enum consumption_draft_update_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  area_id_deprecated = 'area_id_deprecated',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  cost = 'cost',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by_user_id = 'created_by_user_id',
  /** column name */
  display_unit_value = 'display_unit_value',
  /** column name */
  draft_status_deprecated = 'draft_status_deprecated',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  invoice_date_deprecated = 'invoice_date_deprecated',
  /** column name */
  invoice_draft_id = 'invoice_draft_id',
  /** column name */
  invoice_file_id_deprecated = 'invoice_file_id_deprecated',
  /** column name */
  provider = 'provider',
  /** column name */
  source_type_id = 'source_type_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  sub_type_id = 'sub_type_id',
  /** column name */
  to = 'to',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "consumption_frequency_type" */
export enum consumption_frequency_type_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_frequency_type_pkey = 'consumption_frequency_type_pkey',
}

export enum consumption_frequency_type_enum {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  QUARTER_HOURLY = 'QUARTER_HOURLY',
  QUARTER_YEARLY = 'QUARTER_YEARLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY',
}

/** select columns of table "consumption_frequency_type" */
export enum consumption_frequency_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption_frequency_type" */
export enum consumption_frequency_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "consumption_invoice" */
export enum consumption_invoice_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_invoice_pkey = 'consumption_invoice_pkey',
}

/** unique or primary key constraints on table "consumption_invoice_draft" */
export enum consumption_invoice_draft_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_invoice_draft_pkey = 'consumption_invoice_draft_pkey',
}

/** select columns of table "consumption_invoice_draft" */
export enum consumption_invoice_draft_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  date = 'date',
  /** column name */
  draft_status = 'draft_status',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_payer = 'invoice_payer',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "consumption_invoice_draft" */
export enum consumption_invoice_draft_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  date = 'date',
  /** column name */
  draft_status = 'draft_status',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_payer = 'invoice_payer',
  /** column name */
  updated_at = 'updated_at',
}

/** select columns of table "consumption_invoice" */
export enum consumption_invoice_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  date = 'date',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_payer = 'invoice_payer',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "consumption_invoice" */
export enum consumption_invoice_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  date = 'date',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  invoice_payer = 'invoice_payer',
  /** column name */
  updated_at = 'updated_at',
}

/** select columns of table "consumption" */
export enum consumption_select_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  area_id_deprecated = 'area_id_deprecated',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  cost = 'cost',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by_user_id = 'created_by_user_id',
  /** column name */
  display_unit_value = 'display_unit_value',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  invoice_date_deprecated = 'invoice_date_deprecated',
  /** column name */
  invoice_file_id_deprecated = 'invoice_file_id_deprecated',
  /** column name */
  invoice_id = 'invoice_id',
  /** column name */
  meter_id = 'meter_id',
  /** column name */
  provider = 'provider',
  /** column name */
  source_type_id = 'source_type_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  sub_type_id = 'sub_type_id',
  /** column name */
  to = 'to',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "consumption_source_type" */
export enum consumption_source_type_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_source_type_pkey = 'consumption_source_type_pkey',
}

export enum consumption_source_type_enum {
  ESTIMATION = 'ESTIMATION',
  INVOICE = 'INVOICE',
  MANUAL_ENTRY = 'MANUAL_ENTRY',
  METER_READING = 'METER_READING',
}

/** select columns of table "consumption_source_type" */
export enum consumption_source_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption_source_type" */
export enum consumption_source_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "consumption_sub_type" */
export enum consumption_sub_type_constraint {
  /** unique or primary key constraint on columns "id" */
  consumption_sub_type_pkey = 'consumption_sub_type_pkey',
}

export enum consumption_sub_type_enum {
  WASTE_CONSTRUCTION_AND_EXCAVATION = 'WASTE_CONSTRUCTION_AND_EXCAVATION',
  WASTE_ELECTRONIC_EQUIPMENT = 'WASTE_ELECTRONIC_EQUIPMENT',
  WASTE_GLASS = 'WASTE_GLASS',
  WASTE_MUNICIPAL = 'WASTE_MUNICIPAL',
  WASTE_ORGANIC = 'WASTE_ORGANIC',
  WASTE_OTHER_NOT_RECYCLEABLE = 'WASTE_OTHER_NOT_RECYCLEABLE',
  WASTE_OTHER_RECYCLEABLE = 'WASTE_OTHER_RECYCLEABLE',
  WASTE_PACKAGING = 'WASTE_PACKAGING',
  WASTE_PAPER = 'WASTE_PAPER',
  WASTE_RESIDUAL = 'WASTE_RESIDUAL',
  WASTE_WOOD = 'WASTE_WOOD',
  WATER_DRINKING = 'WATER_DRINKING',
  WATER_RAINWATER = 'WATER_RAINWATER',
  WATER_SEWAGE = 'WATER_SEWAGE',
}

/** select columns of table "consumption_sub_type" */
export enum consumption_sub_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption_sub_type" */
export enum consumption_sub_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "consumption_type" */
export enum consumption_type_constraint {
  /** unique or primary key constraint on columns "id" */
  CONSUMPTION_CATEGORY_pkey = 'CONSUMPTION_CATEGORY_pkey',
}

export enum consumption_type_enum {
  ELECTRICITY = 'ELECTRICITY',
  HEATING = 'HEATING',
  WASTE = 'WASTE',
  WATER = 'WATER',
}

/** select columns of table "consumption_type" */
export enum consumption_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption_type" */
export enum consumption_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "consumption_unit" */
export enum consumption_unit_constraint {
  /** unique or primary key constraint on columns "id" */
  CONSUMPTION_UNIT_pkey = 'CONSUMPTION_UNIT_pkey',
}

export enum consumption_unit_enum {
  KWH = 'KWH',
  M3 = 'M3',
  MWh = 'MWh',
  T = 'T',
  WH = 'WH',
  kg = 'kg',
  l = 'l',
}

/** select columns of table "consumption_unit" */
export enum consumption_unit_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption_unit" */
export enum consumption_unit_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "consumption" */
export enum consumption_update_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  area_id_deprecated = 'area_id_deprecated',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  cost = 'cost',
  /** column name */
  created_at = 'created_at',
  /** column name */
  created_by_user_id = 'created_by_user_id',
  /** column name */
  display_unit_value = 'display_unit_value',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  invoice_date_deprecated = 'invoice_date_deprecated',
  /** column name */
  invoice_file_id_deprecated = 'invoice_file_id_deprecated',
  /** column name */
  invoice_id = 'invoice_id',
  /** column name */
  meter_id = 'meter_id',
  /** column name */
  provider = 'provider',
  /** column name */
  source_type_id = 'source_type_id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  sub_type_id = 'sub_type_id',
  /** column name */
  to = 'to',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "consumption_waste_details" */
export enum consumption_waste_details_constraint {
  /** unique or primary key constraint on columns "consumption_id" */
  consumption_waste_details_consumption_id_key = 'consumption_waste_details_consumption_id_key',
  /** unique or primary key constraint on columns "id" */
  consumption_waste_details_pkey = 'consumption_waste_details_pkey',
}

/** unique or primary key constraints on table "consumption_waste_details_draft" */
export enum consumption_waste_details_draft_constraint {
  /** unique or primary key constraint on columns "consumption_draft_id" */
  consumption_waste_details_draft_consumption_draft_id_key = 'consumption_waste_details_draft_consumption_draft_id_key',
  /** unique or primary key constraint on columns "id" */
  consumption_waste_details_draft_pkey = 'consumption_waste_details_draft_pkey',
}

/** select columns of table "consumption_waste_details_draft" */
export enum consumption_waste_details_draft_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  consumption_draft_id = 'consumption_draft_id',
  /** column name */
  display_unit_frequency = 'display_unit_frequency',
  /** column name */
  display_unit_volume = 'display_unit_volume',
  /** column name */
  frequency = 'frequency',
  /** column name */
  id = 'id',
  /** column name */
  price = 'price',
  /** column name */
  volume = 'volume',
}

/** update columns of table "consumption_waste_details_draft" */
export enum consumption_waste_details_draft_update_column {
  /** column name */
  amount = 'amount',
  /** column name */
  consumption_draft_id = 'consumption_draft_id',
  /** column name */
  display_unit_frequency = 'display_unit_frequency',
  /** column name */
  display_unit_volume = 'display_unit_volume',
  /** column name */
  frequency = 'frequency',
  /** column name */
  id = 'id',
  /** column name */
  price = 'price',
  /** column name */
  volume = 'volume',
}

/** select columns of table "consumption_waste_details" */
export enum consumption_waste_details_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  consumption_id = 'consumption_id',
  /** column name */
  display_unit_frequency = 'display_unit_frequency',
  /** column name */
  display_unit_volume = 'display_unit_volume',
  /** column name */
  frequency = 'frequency',
  /** column name */
  id = 'id',
  /** column name */
  price = 'price',
  /** column name */
  volume = 'volume',
}

/** update columns of table "consumption_waste_details" */
export enum consumption_waste_details_update_column {
  /** column name */
  amount = 'amount',
  /** column name */
  consumption_id = 'consumption_id',
  /** column name */
  display_unit_frequency = 'display_unit_frequency',
  /** column name */
  display_unit_volume = 'display_unit_volume',
  /** column name */
  frequency = 'frequency',
  /** column name */
  id = 'id',
  /** column name */
  price = 'price',
  /** column name */
  volume = 'volume',
}

/** unique or primary key constraints on table "country" */
export enum country_constraint {
  /** unique or primary key constraint on columns "id" */
  country_pkey = 'country_pkey',
}

export enum country_enum {
  AT = 'AT',
  DE = 'DE',
  PL = 'PL',
}

/** select columns of table "country" */
export enum country_select_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "country_state" */
export enum country_state_constraint {
  /** unique or primary key constraint on columns "id" */
  country_state_pkey = 'country_state_pkey',
}

export enum country_state_enum {
  AT_BURGENLAND = 'AT_BURGENLAND',
  AT_CARINTHIA = 'AT_CARINTHIA',
  AT_LOWER_AUSTRIA = 'AT_LOWER_AUSTRIA',
  AT_SALZBURG = 'AT_SALZBURG',
  AT_STYRIA = 'AT_STYRIA',
  AT_TYROL = 'AT_TYROL',
  AT_UPPER_AUSTRIA = 'AT_UPPER_AUSTRIA',
  AT_VIENNA = 'AT_VIENNA',
  AT_VORARLBERG = 'AT_VORARLBERG',
  DE_BADEN_WUERTTEMBERG = 'DE_BADEN_WUERTTEMBERG',
  DE_BAYERN = 'DE_BAYERN',
  DE_BERLIN = 'DE_BERLIN',
  DE_BRANDENBURG = 'DE_BRANDENBURG',
  DE_BREMEN = 'DE_BREMEN',
  DE_HAMBURG = 'DE_HAMBURG',
  DE_HESSEN = 'DE_HESSEN',
  DE_MECKLENBURG_VORPOMMERN = 'DE_MECKLENBURG_VORPOMMERN',
  DE_NIEDERSACHSEN = 'DE_NIEDERSACHSEN',
  DE_NORDRHEIN_WESTFALEN = 'DE_NORDRHEIN_WESTFALEN',
  DE_RHEINLAND_PFALZ = 'DE_RHEINLAND_PFALZ',
  DE_SAARLAND = 'DE_SAARLAND',
  DE_SACHSEN = 'DE_SACHSEN',
  DE_SACHSEN_ANHALT = 'DE_SACHSEN_ANHALT',
  DE_SCHLESWIG_HOLSTEIN = 'DE_SCHLESWIG_HOLSTEIN',
  DE_THUERINGEN = 'DE_THUERINGEN',
  PL_GREATER_POLAND = 'PL_GREATER_POLAND',
  PL_HOLY_CROSS = 'PL_HOLY_CROSS',
  PL_KUYAVIAN_POMERANIAN = 'PL_KUYAVIAN_POMERANIAN',
  PL_LESSER_POLAND = 'PL_LESSER_POLAND',
  PL_LODZ = 'PL_LODZ',
  PL_LOWER_SILESIAN = 'PL_LOWER_SILESIAN',
  PL_LUBLIN = 'PL_LUBLIN',
  PL_LUBUSZ = 'PL_LUBUSZ',
  PL_MASOVIAN = 'PL_MASOVIAN',
  PL_OPOLE = 'PL_OPOLE',
  PL_PODLASKIE = 'PL_PODLASKIE',
  PL_POMERANIAN = 'PL_POMERANIAN',
  PL_SILESIAN = 'PL_SILESIAN',
  PL_SUBCARPATHIAN = 'PL_SUBCARPATHIAN',
  PL_WARMIAN_MASURIAN = 'PL_WARMIAN_MASURIAN',
  PL_WEST_POMERANIAN = 'PL_WEST_POMERANIAN',
  UNKNOWN = 'UNKNOWN',
}

/** select columns of table "country_state" */
export enum country_state_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "country_state" */
export enum country_state_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "country" */
export enum country_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "crrem_consumption" */
export enum crrem_consumption_constraint {
  /** unique or primary key constraint on columns "reporting_year", "year", "building_id" */
  crrem_consumption_pkey = 'crrem_consumption_pkey',
}

/** select columns of table "crrem_consumption" */
export enum crrem_consumption_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  co2_intensity = 'co2_intensity',
  /** column name */
  co2_intensity_total = 'co2_intensity_total',
  /** column name */
  energy_consumption = 'energy_consumption',
  /** column name */
  energy_consumption_total = 'energy_consumption_total',
  /** column name */
  excess_emissions_total = 'excess_emissions_total',
  /** column name */
  reporting_year = 'reporting_year',
  /** column name */
  scope1_emissions = 'scope1_emissions',
  /** column name */
  scope1_emissions_total = 'scope1_emissions_total',
  /** column name */
  scope2_emissions = 'scope2_emissions',
  /** column name */
  scope2_emissions_total = 'scope2_emissions_total',
  /** column name */
  year = 'year',
}

/** update columns of table "crrem_consumption" */
export enum crrem_consumption_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  co2_intensity = 'co2_intensity',
  /** column name */
  co2_intensity_total = 'co2_intensity_total',
  /** column name */
  energy_consumption = 'energy_consumption',
  /** column name */
  energy_consumption_total = 'energy_consumption_total',
  /** column name */
  excess_emissions_total = 'excess_emissions_total',
  /** column name */
  reporting_year = 'reporting_year',
  /** column name */
  scope1_emissions = 'scope1_emissions',
  /** column name */
  scope1_emissions_total = 'scope1_emissions_total',
  /** column name */
  scope2_emissions = 'scope2_emissions',
  /** column name */
  scope2_emissions_total = 'scope2_emissions_total',
  /** column name */
  year = 'year',
}

/** ordering argument of a cursor */
export enum cursor_ordering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC',
}

/** unique or primary key constraints on table "data_source_type" */
export enum data_source_type_constraint {
  /** unique or primary key constraint on columns "id" */
  data_source_type_pkey = 'data_source_type_pkey',
}

export enum data_source_type_enum {
  APPROXIMATED = 'APPROXIMATED',
  GEOSPATIAL = 'GEOSPATIAL',
  GOOGLE_SOLAR_API = 'GOOGLE_SOLAR_API',
  MANUAL = 'MANUAL',
  TABULA = 'TABULA',
}

/** select columns of table "data_source_type" */
export enum data_source_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "data_source_type" */
export enum data_source_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "draft_status" */
export enum draft_status_constraint {
  /** unique or primary key constraint on columns "id" */
  draft_status_pkey = 'draft_status_pkey',
}

export enum draft_status_enum {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  OCR_FAILED = 'OCR_FAILED',
  OCR_IN_PROGRESS = 'OCR_IN_PROGRESS',
  OCR_REVIEW = 'OCR_REVIEW',
  UPLOADING = 'UPLOADING',
}

/** select columns of table "draft_status" */
export enum draft_status_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "draft_status" */
export enum draft_status_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "economic_unit" */
export enum economic_unit_constraint {
  /** unique or primary key constraint on columns "id" */
  economic_unit_pkey = 'economic_unit_pkey',
}

/** select columns of table "economic_unit" */
export enum economic_unit_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_economic_unit_id = 'customer_economic_unit_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "economic_unit" */
export enum economic_unit_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  customer_economic_unit_id = 'customer_economic_unit_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "efficiency_class" */
export enum efficiency_class_constraint {
  /** unique or primary key constraint on columns "id" */
  efficiency_class_pkey = 'efficiency_class_pkey',
}

export enum efficiency_class_enum {
  A = 'A',
  AA = 'AA',
  AAA = 'AAA',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
}

/** select columns of table "efficiency_class" */
export enum efficiency_class_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "efficiency_class" */
export enum efficiency_class_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "emission_certificate" */
export enum emission_certificate_constraint {
  /** unique or primary key constraint on columns "id" */
  emission_certificate_pkey = 'emission_certificate_pkey',
}

/** unique or primary key constraints on table "emission_certificate_draft" */
export enum emission_certificate_draft_constraint {
  /** unique or primary key constraint on columns "id" */
  emission_certificate_draft_pkey = 'emission_certificate_draft_pkey',
}

/** select columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  issuer = 'issuer',
  /** column name */
  org_id = 'org_id',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_until = 'valid_until',
}

/** select "emission_certificate_draft_aggregate_bool_exp_avg_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_max_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_min_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_sum_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_draft_aggregate_bool_exp_var_samp_arguments_columns" columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_select_column_emission_certificate_draft_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** update columns of table "emission_certificate_draft" */
export enum emission_certificate_draft_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  issuer = 'issuer',
  /** column name */
  org_id = 'org_id',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_until = 'valid_until',
}

/** select columns of table "emission_certificate" */
export enum emission_certificate_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  issuer = 'issuer',
  /** column name */
  org_id = 'org_id',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_until = 'valid_until',
}

/** select "emission_certificate_aggregate_bool_exp_avg_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_corr_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_max_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_min_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_sum_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** select "emission_certificate_aggregate_bool_exp_var_samp_arguments_columns" columns of table "emission_certificate" */
export enum emission_certificate_select_column_emission_certificate_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
}

/** update columns of table "emission_certificate" */
export enum emission_certificate_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  issuer = 'issuer',
  /** column name */
  org_id = 'org_id',
  /** column name */
  primary_energy_factor = 'primary_energy_factor',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  valid_from = 'valid_from',
  /** column name */
  valid_until = 'valid_until',
}

/** unique or primary key constraints on table "emission_factor_type" */
export enum emission_factor_type_constraint {
  /** unique or primary key constraint on columns "id" */
  emission_factor_type_pkey = 'emission_factor_type_pkey',
}

export enum emission_factor_type_enum {
  CO2TAX = 'CO2TAX',
  CRREM = 'CRREM',
  GEG = 'GEG',
}

/** select columns of table "emission_factor_type" */
export enum emission_factor_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "emission_factor_type" */
export enum emission_factor_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "energy_certificate" */
export enum energy_certificate_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_certificate_residential_demand_pkey = 'energy_certificate_residential_demand_pkey',
}

/** unique or primary key constraints on table "energy_certificate_consumer" */
export enum energy_certificate_consumer_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_certificate_consumer_pkey = 'energy_certificate_consumer_pkey',
}

/** select columns of table "energy_certificate_consumer" */
export enum energy_certificate_consumer_select_column {
  /** column name */
  energy_certificate_draft_id = 'energy_certificate_draft_id',
  /** column name */
  energy_certificate_id = 'energy_certificate_id',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_system_type_id = 'energy_system_type_id',
  /** column name */
  id = 'id',
}

/** update columns of table "energy_certificate_consumer" */
export enum energy_certificate_consumer_update_column {
  /** column name */
  energy_certificate_draft_id = 'energy_certificate_draft_id',
  /** column name */
  energy_certificate_id = 'energy_certificate_id',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_system_type_id = 'energy_system_type_id',
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "energy_certificate_draft" */
export enum energy_certificate_draft_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_certificate_draft_pkey = 'energy_certificate_draft_pkey',
}

/** select columns of table "energy_certificate_draft" */
export enum energy_certificate_draft_select_column {
  /** column name */
  area_usable = 'area_usable',
  /** column name */
  building_state_id = 'building_state_id',
  /** column name */
  city = 'city',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_status_id = 'draft_status_id',
  /** column name */
  economic_unit_id = 'economic_unit_id',
  /** column name */
  efficiency_class_id = 'efficiency_class_id',
  /** column name */
  energy_certificate_file_id = 'energy_certificate_file_id',
  /** column name */
  energy_certificate_type_id = 'energy_certificate_type_id',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  expiry_date = 'expiry_date',
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  id = 'id',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
  /** column name */
  org_id = 'org_id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  street = 'street',
  /** column name */
  sub_building_class_id = 'sub_building_class_id',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  units = 'units',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
  /** column name */
  year_renovated = 'year_renovated',
}

/** select "energy_certificate_draft_aggregate_bool_exp_bool_and_arguments_columns" columns of table "energy_certificate_draft" */
export enum energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
}

/** select "energy_certificate_draft_aggregate_bool_exp_bool_or_arguments_columns" columns of table "energy_certificate_draft" */
export enum energy_certificate_draft_select_column_energy_certificate_draft_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
}

/** update columns of table "energy_certificate_draft" */
export enum energy_certificate_draft_update_column {
  /** column name */
  area_usable = 'area_usable',
  /** column name */
  building_state_id = 'building_state_id',
  /** column name */
  city = 'city',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  draft_status_id = 'draft_status_id',
  /** column name */
  economic_unit_id = 'economic_unit_id',
  /** column name */
  efficiency_class_id = 'efficiency_class_id',
  /** column name */
  energy_certificate_file_id = 'energy_certificate_file_id',
  /** column name */
  energy_certificate_type_id = 'energy_certificate_type_id',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  expiry_date = 'expiry_date',
  /** column name */
  heritage_district = 'heritage_district',
  /** column name */
  id = 'id',
  /** column name */
  leasehold = 'leasehold',
  /** column name */
  milieu_protection = 'milieu_protection',
  /** column name */
  monument_protection = 'monument_protection',
  /** column name */
  org_id = 'org_id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  street = 'street',
  /** column name */
  sub_building_class_id = 'sub_building_class_id',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  units = 'units',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
  /** column name */
  year_renovated = 'year_renovated',
}

/** select columns of table "energy_certificate" */
export enum energy_certificate_select_column {
  /** column name */
  area_usable = 'area_usable',
  /** column name */
  city = 'city',
  /** column name */
  co2_emissions = 'co2_emissions',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  efficiency_class = 'efficiency_class',
  /** column name */
  energy_certificate_type = 'energy_certificate_type',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  expiry_date = 'expiry_date',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  state = 'state',
  /** column name */
  street = 'street',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  units_commercial = 'units_commercial',
  /** column name */
  units_residential = 'units_residential',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
  /** column name */
  year_renovated = 'year_renovated',
}

/** unique or primary key constraints on table "energy_certificate_type" */
export enum energy_certificate_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_certificate_type_pkey = 'energy_certificate_type_pkey',
}

export enum energy_certificate_type_enum {
  CONSUMPTION = 'CONSUMPTION',
  DEMAND = 'DEMAND',
}

/** select columns of table "energy_certificate_type" */
export enum energy_certificate_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_certificate_type" */
export enum energy_certificate_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_certificate" */
export enum energy_certificate_update_column {
  /** column name */
  area_usable = 'area_usable',
  /** column name */
  city = 'city',
  /** column name */
  co2_emissions = 'co2_emissions',
  /** column name */
  country_id = 'country_id',
  /** column name */
  country_state_id = 'country_state_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  efficiency_class = 'efficiency_class',
  /** column name */
  energy_certificate_type = 'energy_certificate_type',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  expiry_date = 'expiry_date',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  state = 'state',
  /** column name */
  street = 'street',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  units_commercial = 'units_commercial',
  /** column name */
  units_residential = 'units_residential',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_constructed = 'year_constructed',
  /** column name */
  year_renovated = 'year_renovated',
}

/** unique or primary key constraints on table "energy_consumer" */
export enum energy_consumer_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_consumer_pkey = 'energy_consumer_pkey',
}

/** select columns of table "energy_consumer" */
export enum energy_consumer_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  created_at = 'created_at',
  /** column name */
  efficiency = 'efficiency',
  /** column name */
  energy_consumer_technology_type_id = 'energy_consumer_technology_type_id',
  /** column name */
  energy_consumer_type_id = 'energy_consumer_type_id',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "energy_consumer_technology_type" */
export enum energy_consumer_technology_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_consumer_technology_type_pkey = 'energy_consumer_technology_type_pkey',
}

export enum energy_consumer_technology_type_enum {
  CENTRAL_AIR_CONDITIONING = 'CENTRAL_AIR_CONDITIONING',
  CENTRAL_ELECTRIC_STORAGE = 'CENTRAL_ELECTRIC_STORAGE',
  CENTRAL_EXHAUST = 'CENTRAL_EXHAUST',
  CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU = 'CENTRAL_SUPPLY_EXHAUST_WITHOUT_HRU',
  CENTRAL_SUPPLY_EXHAUST_WITH_HRU = 'CENTRAL_SUPPLY_EXHAUST_WITH_HRU',
  COAL_FURNACE = 'COAL_FURNACE',
  CONDENSING_BOILER = 'CONDENSING_BOILER',
  DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU = 'DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITHOUT_HRU',
  DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU = 'DECENTRAL_SINGLE_ROOM_VENTILATION_UNITS_WITH_HRU',
  DIRECT_ELECTRICITY_HEATING = 'DIRECT_ELECTRICITY_HEATING',
  DISTRICT_HEATING_WITHOUT_KWK = 'DISTRICT_HEATING_WITHOUT_KWK',
  DISTRICT_HEATING_WITH_KWK = 'DISTRICT_HEATING_WITH_KWK',
  ELECTRICITY_MISCELLANEOUS = 'ELECTRICITY_MISCELLANEOUS',
  ELECTRIC_FLOW_HEATER = 'ELECTRIC_FLOW_HEATER',
  ELECTRIC_HEAT_PUMP_AIR = 'ELECTRIC_HEAT_PUMP_AIR',
  ELECTRIC_HEAT_PUMP_GEO = 'ELECTRIC_HEAT_PUMP_GEO',
  ELECTRIC_IMMERSION_HEATER = 'ELECTRIC_IMMERSION_HEATER',
  FLUORESCENT_LAMPS = 'FLUORESCENT_LAMPS',
  GAS_CONDENSING_BOILER = 'GAS_CONDENSING_BOILER',
  GAS_FLOOR_HEATING = 'GAS_FLOOR_HEATING',
  GAS_FLOW_HEATER = 'GAS_FLOW_HEATER',
  GAS_NON_CONDENSING_BOILER = 'GAS_NON_CONDENSING_BOILER',
  GAS_ROOM_HEATER = 'GAS_ROOM_HEATER',
  HALOGEN_LAMPS = 'HALOGEN_LAMPS',
  INCANDESCENT_LAMPS = 'INCANDESCENT_LAMPS',
  LED = 'LED',
  LOW_TEMPERATURE_BOILER = 'LOW_TEMPERATURE_BOILER',
  OIL_FURNACE = 'OIL_FURNACE',
  REVERSIBLE_AIR_WATER_HEAT_PUMP = 'REVERSIBLE_AIR_WATER_HEAT_PUMP',
  REVERSIBLE_BRINE_WATER_HEAT_PUMP = 'REVERSIBLE_BRINE_WATER_HEAT_PUMP',
  ROOM_AIR_CONDITIONING = 'ROOM_AIR_CONDITIONING',
  SMALL_ELECTRIC_STORAGE = 'SMALL_ELECTRIC_STORAGE',
  SOLAR_PLANT = 'SOLAR_PLANT',
  STANDARD_BOILER = 'STANDARD_BOILER',
  WOOD_BOILER = 'WOOD_BOILER',
  WOOD_FURNACE = 'WOOD_FURNACE',
}

/** select columns of table "energy_consumer_technology_type" */
export enum energy_consumer_technology_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_consumer_technology_type" */
export enum energy_consumer_technology_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "energy_consumer_type" */
export enum energy_consumer_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_source_coverage_pkey = 'energy_source_coverage_pkey',
}

export enum energy_consumer_type_enum {
  COOLING = 'COOLING',
  ELECTRICITY = 'ELECTRICITY',
  GENERAL = 'GENERAL',
  HEATING = 'HEATING',
  HOT_WATER = 'HOT_WATER',
  LIGHTING = 'LIGHTING',
  VENTILATION = 'VENTILATION',
}

/** select columns of table "energy_consumer_type" */
export enum energy_consumer_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_consumer_type" */
export enum energy_consumer_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_consumer" */
export enum energy_consumer_update_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  created_at = 'created_at',
  /** column name */
  efficiency = 'efficiency',
  /** column name */
  energy_consumer_technology_type_id = 'energy_consumer_technology_type_id',
  /** column name */
  energy_consumer_type_id = 'energy_consumer_type_id',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
}

export enum energy_consumption_type_enum {
  ENERGY = 'ENERGY',
  WASTE = 'WASTE',
  WATER = 'WATER',
}

/** unique or primary key constraints on table "energy_distribution" */
export enum energy_distribution_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_distribution_pkey = 'energy_distribution_pkey',
}

/** select columns of table "energy_distribution" */
export enum energy_distribution_select_column {
  /** column name */
  balancing_with_components = 'balancing_with_components',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  energy_distribution_type_id = 'energy_distribution_type_id',
  /** column name */
  heat_distribution_loss = 'heat_distribution_loss',
  /** column name */
  heat_transfer_loss = 'heat_transfer_loss',
  /** column name */
  id = 'id',
  /** column name */
  last_balancing_set_to_never = 'last_balancing_set_to_never',
  /** column name */
  last_hydraulic_balancing = 'last_hydraulic_balancing',
  /** column name */
  recoverable_heat_loss = 'recoverable_heat_loss',
}

/** select "energy_distribution_aggregate_bool_exp_bool_and_arguments_columns" columns of table "energy_distribution" */
export enum energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  balancing_with_components = 'balancing_with_components',
  /** column name */
  last_balancing_set_to_never = 'last_balancing_set_to_never',
}

/** select "energy_distribution_aggregate_bool_exp_bool_or_arguments_columns" columns of table "energy_distribution" */
export enum energy_distribution_select_column_energy_distribution_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  balancing_with_components = 'balancing_with_components',
  /** column name */
  last_balancing_set_to_never = 'last_balancing_set_to_never',
}

/** unique or primary key constraints on table "energy_distribution_type" */
export enum energy_distribution_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_distribution_type_pkey = 'energy_distribution_type_pkey',
}

export enum energy_distribution_type_enum {
  AIR_DUCTS_INTERNAL = 'AIR_DUCTS_INTERNAL',
  CENTRAL_CIRCULATION_INSIDE = 'CENTRAL_CIRCULATION_INSIDE',
  CENTRAL_CIRCULATION_OUTSIDE = 'CENTRAL_CIRCULATION_OUTSIDE',
  CENTRAL_INSIDE = 'CENTRAL_INSIDE',
  CENTRAL_NO_CIRCULATION_INSIDE = 'CENTRAL_NO_CIRCULATION_INSIDE',
  CENTRAL_NO_CIRCULATION_OUTSIDE = 'CENTRAL_NO_CIRCULATION_OUTSIDE',
  CENTRAL_OUTSIDE = 'CENTRAL_OUTSIDE',
  DECENTRAL = 'DECENTRAL',
  FAN = 'FAN',
  THERMAL_COMPONENT_ACTIVATION = 'THERMAL_COMPONENT_ACTIVATION',
}

/** select columns of table "energy_distribution_type" */
export enum energy_distribution_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_distribution_type" */
export enum energy_distribution_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_distribution" */
export enum energy_distribution_update_column {
  /** column name */
  balancing_with_components = 'balancing_with_components',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  energy_distribution_type_id = 'energy_distribution_type_id',
  /** column name */
  heat_distribution_loss = 'heat_distribution_loss',
  /** column name */
  heat_transfer_loss = 'heat_transfer_loss',
  /** column name */
  id = 'id',
  /** column name */
  last_balancing_set_to_never = 'last_balancing_set_to_never',
  /** column name */
  last_hydraulic_balancing = 'last_hydraulic_balancing',
  /** column name */
  recoverable_heat_loss = 'recoverable_heat_loss',
}

/** unique or primary key constraints on table "energy_path" */
export enum energy_path_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_path_pkey = 'energy_path_pkey',
}

/** select columns of table "energy_path" */
export enum energy_path_select_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  co2_costs = 'co2_costs',
  /** column name */
  co2_costs_total = 'co2_costs_total',
  /** column name */
  co2_emissions = 'co2_emissions',
  /** column name */
  co2_emissions_total = 'co2_emissions_total',
  /** column name */
  crrem_co2_emissions = 'crrem_co2_emissions',
  /** column name */
  crrem_co2_emissions_total = 'crrem_co2_emissions_total',
  /** column name */
  crrem_energy_consumption = 'crrem_energy_consumption',
  /** column name */
  crrem_energy_consumption_total = 'crrem_energy_consumption_total',
  /** column name */
  crrem_excess_emissions_total = 'crrem_excess_emissions_total',
  /** column name */
  efficiency_class_id = 'efficiency_class_id',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  energy_consumption = 'energy_consumption',
  /** column name */
  energy_consumption_total = 'energy_consumption_total',
  /** column name */
  energy_costs = 'energy_costs',
  /** column name */
  energy_costs_total = 'energy_costs_total',
  /** column name */
  energy_system_consumer_route_id = 'energy_system_consumer_route_id',
  /** column name */
  eu_taxonomy_compliance_id = 'eu_taxonomy_compliance_id',
  /** column name */
  id = 'id',
  /** column name */
  scope_1_co2_emissions = 'scope_1_co2_emissions',
  /** column name */
  scope_1_co2_emissions_total = 'scope_1_co2_emissions_total',
  /** column name */
  scope_2_co2_emissions = 'scope_2_co2_emissions',
  /** column name */
  scope_2_co2_emissions_total = 'scope_2_co2_emissions_total',
  /** column name */
  scope_3_co2_emissions = 'scope_3_co2_emissions',
  /** column name */
  scope_3_co2_emissions_total = 'scope_3_co2_emissions_total',
  /** column name */
  tax_bracket_id = 'tax_bracket_id',
  /** column name */
  year = 'year',
}

/** update columns of table "energy_path" */
export enum energy_path_update_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  co2_costs = 'co2_costs',
  /** column name */
  co2_costs_total = 'co2_costs_total',
  /** column name */
  co2_emissions = 'co2_emissions',
  /** column name */
  co2_emissions_total = 'co2_emissions_total',
  /** column name */
  crrem_co2_emissions = 'crrem_co2_emissions',
  /** column name */
  crrem_co2_emissions_total = 'crrem_co2_emissions_total',
  /** column name */
  crrem_energy_consumption = 'crrem_energy_consumption',
  /** column name */
  crrem_energy_consumption_total = 'crrem_energy_consumption_total',
  /** column name */
  crrem_excess_emissions_total = 'crrem_excess_emissions_total',
  /** column name */
  efficiency_class_id = 'efficiency_class_id',
  /** column name */
  emission_factor = 'emission_factor',
  /** column name */
  energy_consumption = 'energy_consumption',
  /** column name */
  energy_consumption_total = 'energy_consumption_total',
  /** column name */
  energy_costs = 'energy_costs',
  /** column name */
  energy_costs_total = 'energy_costs_total',
  /** column name */
  energy_system_consumer_route_id = 'energy_system_consumer_route_id',
  /** column name */
  eu_taxonomy_compliance_id = 'eu_taxonomy_compliance_id',
  /** column name */
  id = 'id',
  /** column name */
  scope_1_co2_emissions = 'scope_1_co2_emissions',
  /** column name */
  scope_1_co2_emissions_total = 'scope_1_co2_emissions_total',
  /** column name */
  scope_2_co2_emissions = 'scope_2_co2_emissions',
  /** column name */
  scope_2_co2_emissions_total = 'scope_2_co2_emissions_total',
  /** column name */
  scope_3_co2_emissions = 'scope_3_co2_emissions',
  /** column name */
  scope_3_co2_emissions_total = 'scope_3_co2_emissions_total',
  /** column name */
  tax_bracket_id = 'tax_bracket_id',
  /** column name */
  year = 'year',
}

/** unique or primary key constraints on table "energy_producer" */
export enum energy_producer_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_producer_pkey = 'energy_producer_pkey',
}

/** select columns of table "energy_producer" */
export enum energy_producer_select_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  efficiency = 'efficiency',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  id = 'id',
  /** column name */
  module_angle = 'module_angle',
  /** column name */
  module_peak_power = 'module_peak_power',
  /** column name */
  name = 'name',
  /** column name */
  nominal_power = 'nominal_power',
  /** column name */
  roof_area_ratio = 'roof_area_ratio',
  /** column name */
  solar_active_area = 'solar_active_area',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** update columns of table "energy_producer" */
export enum energy_producer_update_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  efficiency = 'efficiency',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  id = 'id',
  /** column name */
  module_angle = 'module_angle',
  /** column name */
  module_peak_power = 'module_peak_power',
  /** column name */
  name = 'name',
  /** column name */
  nominal_power = 'nominal_power',
  /** column name */
  roof_area_ratio = 'roof_area_ratio',
  /** column name */
  solar_active_area = 'solar_active_area',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** unique or primary key constraints on table "energy_source" */
export enum energy_source_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_source_pkey = 'energy_source_pkey',
}

/** select columns of table "energy_source" */
export enum energy_source_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_certificate_draft_id = 'energy_certificate_draft_id',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  energy_source_type = 'energy_source_type',
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "energy_source_type" */
export enum energy_source_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_source_type_pkey = 'energy_source_type_pkey',
}

export enum energy_source_type_enum {
  BIO_GAS = 'BIO_GAS',
  COAL = 'COAL',
  DISTRICT_HEATING_CHP_FOSSIL_COAL = 'DISTRICT_HEATING_CHP_FOSSIL_COAL',
  DISTRICT_HEATING_CHP_FOSSIL_GAS = 'DISTRICT_HEATING_CHP_FOSSIL_GAS',
  DISTRICT_HEATING_CHP_RENEWABLE = 'DISTRICT_HEATING_CHP_RENEWABLE',
  DISTRICT_HEATING_PLANTS_FOSSIL_COAL = 'DISTRICT_HEATING_PLANTS_FOSSIL_COAL',
  DISTRICT_HEATING_PLANTS_FOSSIL_GAS = 'DISTRICT_HEATING_PLANTS_FOSSIL_GAS',
  DISTRICT_HEATING_PLANTS_RENEWABLE = 'DISTRICT_HEATING_PLANTS_RENEWABLE',
  ELECTRICITY_GREEN = 'ELECTRICITY_GREEN',
  ELECTRICITY_MIX = 'ELECTRICITY_MIX',
  FUEL_OIL = 'FUEL_OIL',
  LIGNITE = 'LIGNITE',
  LPG = 'LPG',
  NATURAL_GAS = 'NATURAL_GAS',
  SOLAR = 'SOLAR',
  UNSPECIFIED = 'UNSPECIFIED',
  WOOD = 'WOOD',
  WOODEN_PELLETS = 'WOODEN_PELLETS',
}

/** select columns of table "energy_source_type" */
export enum energy_source_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_source_type" */
export enum energy_source_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_source" */
export enum energy_source_update_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_certificate_draft_id = 'energy_certificate_draft_id',
  /** column name */
  energy_final_consumption = 'energy_final_consumption',
  /** column name */
  energy_final_demand = 'energy_final_demand',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_primary_consumption = 'energy_primary_consumption',
  /** column name */
  energy_primary_demand = 'energy_primary_demand',
  /** column name */
  energy_source_type = 'energy_source_type',
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "energy_standard" */
export enum energy_standard_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_standard_pkey = 'energy_standard_pkey',
}

export enum energy_standard_enum {
  GEG_2020 = 'GEG_2020',
  KFW40 = 'KFW40',
  KFW55 = 'KFW55',
  KFW70 = 'KFW70',
  KFW85 = 'KFW85',
  KFW100 = 'KFW100',
  KFW115 = 'KFW115',
  KFW_DENKMAL = 'KFW_DENKMAL',
  PASSIVHAUS = 'PASSIVHAUS',
  UNSPECIFIED = 'UNSPECIFIED',
}

/** select columns of table "energy_standard" */
export enum energy_standard_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_standard" */
export enum energy_standard_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "energy_storage" */
export enum energy_storage_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_storage_pkey = 'energy_storage_pkey',
}

/** select columns of table "energy_storage" */
export enum energy_storage_select_column {
  /** column name */
  annual_heat_loss = 'annual_heat_loss',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  energy_storage_type_id = 'energy_storage_type_id',
  /** column name */
  id = 'id',
  /** column name */
  recoverable_heat_loss = 'recoverable_heat_loss',
}

/** unique or primary key constraints on table "energy_storage_type" */
export enum energy_storage_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_storage_type_pkey = 'energy_storage_type_pkey',
}

export enum energy_storage_type_enum {
  BUFFER_STORAGE = 'BUFFER_STORAGE',
  BUFFER_STORAGE_ELECTRIC = 'BUFFER_STORAGE_ELECTRIC',
  CENTRAL_INSIDE = 'CENTRAL_INSIDE',
  CENTRAL_OUTSIDE = 'CENTRAL_OUTSIDE',
}

/** select columns of table "energy_storage_type" */
export enum energy_storage_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_storage_type" */
export enum energy_storage_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_storage" */
export enum energy_storage_update_column {
  /** column name */
  annual_heat_loss = 'annual_heat_loss',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  construction_year = 'construction_year',
  /** column name */
  energy_storage_type_id = 'energy_storage_type_id',
  /** column name */
  id = 'id',
  /** column name */
  recoverable_heat_loss = 'recoverable_heat_loss',
}

/** unique or primary key constraints on table "energy_system" */
export enum energy_system_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_system_pkey = 'energy_system_pkey',
}

/** unique or primary key constraints on table "energy_system_consumer_route" */
export enum energy_system_consumer_route_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_system_consumer_route_pkey = 'energy_system_consumer_route_pkey',
}

/** select columns of table "energy_system_consumer_route" */
export enum energy_system_consumer_route_select_column {
  /** column name */
  emission_certificate_id = 'emission_certificate_id',
  /** column name */
  energy_consumer_id = 'energy_consumer_id',
  /** column name */
  energy_distribution_id = 'energy_distribution_id',
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_storage_id = 'energy_storage_id',
  /** column name */
  energy_system_id = 'energy_system_id',
  /** column name */
  id = 'id',
  /** column name */
  primary_energy_factor_emission_certificate_id = 'primary_energy_factor_emission_certificate_id',
}

/** update columns of table "energy_system_consumer_route" */
export enum energy_system_consumer_route_update_column {
  /** column name */
  emission_certificate_id = 'emission_certificate_id',
  /** column name */
  energy_consumer_id = 'energy_consumer_id',
  /** column name */
  energy_distribution_id = 'energy_distribution_id',
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_final = 'energy_final',
  /** column name */
  energy_primary = 'energy_primary',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_storage_id = 'energy_storage_id',
  /** column name */
  energy_system_id = 'energy_system_id',
  /** column name */
  id = 'id',
  /** column name */
  primary_energy_factor_emission_certificate_id = 'primary_energy_factor_emission_certificate_id',
}

/** unique or primary key constraints on table "energy_system_producer_route" */
export enum energy_system_producer_route_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_system_producer_route_pkey = 'energy_system_producer_route_pkey',
}

/** select columns of table "energy_system_producer_route" */
export enum energy_system_producer_route_select_column {
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_produced = 'energy_produced',
  /** column name */
  energy_producer_id = 'energy_producer_id',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_system_id = 'energy_system_id',
  /** column name */
  id = 'id',
}

/** update columns of table "energy_system_producer_route" */
export enum energy_system_producer_route_update_column {
  /** column name */
  energy_effective = 'energy_effective',
  /** column name */
  energy_produced = 'energy_produced',
  /** column name */
  energy_producer_id = 'energy_producer_id',
  /** column name */
  energy_source_type_id = 'energy_source_type_id',
  /** column name */
  energy_system_id = 'energy_system_id',
  /** column name */
  id = 'id',
}

/** select columns of table "energy_system" */
export enum energy_system_select_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  data_source_type_id = 'data_source_type_id',
  /** column name */
  energy_system_type_id = 'energy_system_type_id',
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** unique or primary key constraints on table "energy_system_type" */
export enum energy_system_type_constraint {
  /** unique or primary key constraint on columns "id" */
  energy_system_type_pkey = 'energy_system_type_pkey',
}

export enum energy_system_type_enum {
  COOLING = 'COOLING',
  ELECTRICITY = 'ELECTRICITY',
  GENERAL = 'GENERAL',
  HEATING = 'HEATING',
  HOT_WATER = 'HOT_WATER',
  LIGHTING = 'LIGHTING',
  SOLAR = 'SOLAR',
  VENTILATION = 'VENTILATION',
}

/** select columns of table "energy_system_type" */
export enum energy_system_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_system_type" */
export enum energy_system_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "energy_system" */
export enum energy_system_update_column {
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  data_source_type_id = 'data_source_type_id',
  /** column name */
  energy_system_type_id = 'energy_system_type_id',
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** unique or primary key constraints on table "envelope" */
export enum envelope_constraint {
  /** unique or primary key constraint on columns "id" */
  envelope_pkey = 'envelope_pkey',
}

/** select columns of table "envelope" */
export enum envelope_select_column {
  /** column name */
  area = 'area',
  /** column name */
  base_construction_lambda = 'base_construction_lambda',
  /** column name */
  base_construction_material_name = 'base_construction_material_name',
  /** column name */
  base_construction_thickness = 'base_construction_thickness',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  data_source_type_id = 'data_source_type_id',
  /** column name */
  envelope_type_id = 'envelope_type_id',
  /** column name */
  id = 'id',
  /** column name */
  inclination = 'inclination',
  /** column name */
  insulation_lambda = 'insulation_lambda',
  /** column name */
  insulation_material_category_id = 'insulation_material_category_id',
  /** column name */
  insulation_material_name = 'insulation_material_name',
  /** column name */
  insulation_method_id = 'insulation_method_id',
  /** column name */
  insulation_thickness = 'insulation_thickness',
  /** column name */
  orientation = 'orientation',
  /** column name */
  u_value = 'u_value',
}

/** unique or primary key constraints on table "envelope_type" */
export enum envelope_type_constraint {
  /** unique or primary key constraint on columns "id" */
  envelope_type_pkey = 'envelope_type_pkey',
}

export enum envelope_type_enum {
  BASEMENT_CEILING = 'BASEMENT_CEILING',
  DOOR = 'DOOR',
  FLAT_ROOF = 'FLAT_ROOF',
  FLOOR = 'FLOOR',
  PITCHED_ROOF = 'PITCHED_ROOF',
  TOP_FLOOR_CEILING = 'TOP_FLOOR_CEILING',
  WALL = 'WALL',
  WINDOW = 'WINDOW',
}

/** select columns of table "envelope_type" */
export enum envelope_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "envelope_type" */
export enum envelope_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "envelope" */
export enum envelope_update_column {
  /** column name */
  area = 'area',
  /** column name */
  base_construction_lambda = 'base_construction_lambda',
  /** column name */
  base_construction_material_name = 'base_construction_material_name',
  /** column name */
  base_construction_thickness = 'base_construction_thickness',
  /** column name */
  building_model_id = 'building_model_id',
  /** column name */
  cost_per_m2 = 'cost_per_m2',
  /** column name */
  data_source_type_id = 'data_source_type_id',
  /** column name */
  envelope_type_id = 'envelope_type_id',
  /** column name */
  id = 'id',
  /** column name */
  inclination = 'inclination',
  /** column name */
  insulation_lambda = 'insulation_lambda',
  /** column name */
  insulation_material_category_id = 'insulation_material_category_id',
  /** column name */
  insulation_material_name = 'insulation_material_name',
  /** column name */
  insulation_method_id = 'insulation_method_id',
  /** column name */
  insulation_thickness = 'insulation_thickness',
  /** column name */
  orientation = 'orientation',
  /** column name */
  u_value = 'u_value',
}

/** unique or primary key constraints on table "eu_taxonomy_compliance" */
export enum eu_taxonomy_compliance_constraint {
  /** unique or primary key constraint on columns "id" */
  eu_taxonomy_compliance_pkey = 'eu_taxonomy_compliance_pkey',
}

export enum eu_taxonomy_compliance_enum {
  COMPLIANT = 'COMPLIANT',
  MEETS_DNSH_CRITERIA = 'MEETS_DNSH_CRITERIA',
  NON_COMPLIANT = 'NON_COMPLIANT',
  UNKNOWN = 'UNKNOWN',
}

/** select columns of table "eu_taxonomy_compliance" */
export enum eu_taxonomy_compliance_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "eu_taxonomy_compliance" */
export enum eu_taxonomy_compliance_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "file" */
export enum file_constraint {
  /** unique or primary key constraint on columns "id" */
  file_pkey = 'file_pkey',
  /** unique or primary key constraint on columns "s3_key" */
  file_s3_key_key = 'file_s3_key_key',
}

/** select columns of table "file" */
export enum file_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  s3_key = 's3_key',
  /** column name */
  uploading = 'uploading',
}

/** update columns of table "file" */
export enum file_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  s3_key = 's3_key',
  /** column name */
  uploading = 'uploading',
}

/** unique or primary key constraints on table "insulation_material_category" */
export enum insulation_material_category_constraint {
  /** unique or primary key constraint on columns "id" */
  insulation_material_category_pkey = 'insulation_material_category_pkey',
}

export enum insulation_material_category_enum {
  MINERAL = 'MINERAL',
  MIXED = 'MIXED',
  RENEWABLE = 'RENEWABLE',
  SYNTHETIC = 'SYNTHETIC',
}

/** select columns of table "insulation_material_category" */
export enum insulation_material_category_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "insulation_material_category" */
export enum insulation_material_category_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "insulation_method" */
export enum insulation_method_constraint {
  /** unique or primary key constraint on columns "id" */
  insulation_method_pkey = 'insulation_method_pkey',
}

export enum insulation_method_enum {
  ABOVE_RAFTER = 'ABOVE_RAFTER',
  BELOW_RAFTER = 'BELOW_RAFTER',
  BETWEEN_RAFTER = 'BETWEEN_RAFTER',
  BLOW_IN_INSULATION = 'BLOW_IN_INSULATION',
  COLD_ROOF_BETWEEN_RAFTER = 'COLD_ROOF_BETWEEN_RAFTER',
  CORE_INSULATION_BOARDS = 'CORE_INSULATION_BOARDS',
  FACING_SHELL = 'FACING_SHELL',
  FROM_BELOW = 'FROM_BELOW',
  INSULATION_BOARDS = 'INSULATION_BOARDS',
  INSULATION_BOARDS_WALKABLE = 'INSULATION_BOARDS_WALKABLE',
  INSULATION_BOARDS_WITHOUT_CLADDING = 'INSULATION_BOARDS_WITHOUT_CLADDING',
  INSULATION_BOARDS_WITH_CLADDING = 'INSULATION_BOARDS_WITH_CLADDING',
  INSULATION_GRADIENT = 'INSULATION_GRADIENT',
  INSULATION_MATS = 'INSULATION_MATS',
  INTERIOR_PLASTER_SYSTEM = 'INTERIOR_PLASTER_SYSTEM',
  INVERTED_ROOF = 'INVERTED_ROOF',
  ON_RAFTER = 'ON_RAFTER',
  VHF = 'VHF',
  WARM_ROOF = 'WARM_ROOF',
  WDVS = 'WDVS',
}

/** select columns of table "insulation_method" */
export enum insulation_method_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "insulation_method" */
export enum insulation_method_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "invoice_payer_type" */
export enum invoice_payer_type_constraint {
  /** unique or primary key constraint on columns "id" */
  invoice_payer_type_pkey = 'invoice_payer_type_pkey',
}

export enum invoice_payer_type_enum {
  OWNER = 'OWNER',
  TENANT = 'TENANT',
}

/** select columns of table "invoice_payer_type" */
export enum invoice_payer_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "invoice_payer_type" */
export enum invoice_payer_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "langfuse_datasets_temporary_item" */
export enum langfuse_datasets_temporary_item_constraint {
  /** unique or primary key constraint on columns "id" */
  langfuse_datasets_temporary_item_pkey = 'langfuse_datasets_temporary_item_pkey',
}

/** select columns of table "langfuse_datasets_temporary_item" */
export enum langfuse_datasets_temporary_item_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  dataset_name = 'dataset_name',
  /** column name */
  entity_id = 'entity_id',
  /** column name */
  entity_type = 'entity_type',
  /** column name */
  expected_output = 'expected_output',
  /** column name */
  id = 'id',
  /** column name */
  input = 'input',
  /** column name */
  org_id = 'org_id',
  /** column name */
  output = 'output',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "langfuse_datasets_temporary_item" */
export enum langfuse_datasets_temporary_item_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  dataset_name = 'dataset_name',
  /** column name */
  entity_id = 'entity_id',
  /** column name */
  entity_type = 'entity_type',
  /** column name */
  expected_output = 'expected_output',
  /** column name */
  id = 'id',
  /** column name */
  input = 'input',
  /** column name */
  org_id = 'org_id',
  /** column name */
  output = 'output',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "language" */
export enum language_constraint {
  /** unique or primary key constraint on columns "id" */
  language_pkey = 'language_pkey',
}

export enum language_enum {
  /** the main language and fallback of the application */
  deDE = 'deDE',
  enGB = 'enGB',
}

/** select columns of table "language" */
export enum language_select_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
}

/** update columns of table "language" */
export enum language_update_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "meter" */
export enum meter_constraint {
  /** unique or primary key constraint on columns "id" */
  meter_pkey = 'meter_pkey',
}

/** unique or primary key constraints on table "meter_integration_building" */
export enum meter_integration_building_constraint {
  /** unique or primary key constraint on columns "id" */
  meter_integration_building_pkey = 'meter_integration_building_pkey',
}

/** select columns of table "meter_integration_building" */
export enum meter_integration_building_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  external_id = 'external_id',
  /** column name */
  id = 'id',
  /** column name */
  meter_integration_id = 'meter_integration_id',
  /** column name */
  properties = 'properties',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "meter_integration_building" */
export enum meter_integration_building_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  external_id = 'external_id',
  /** column name */
  id = 'id',
  /** column name */
  meter_integration_id = 'meter_integration_id',
  /** column name */
  properties = 'properties',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "meter_integration" */
export enum meter_integration_constraint {
  /** unique or primary key constraint on columns "id" */
  meter_integration_pkey = 'meter_integration_pkey',
}

/** select columns of table "meter_integration" */
export enum meter_integration_select_column {
  /** column name */
  api_key = 'api_key',
  /** column name */
  api_metadata = 'api_metadata',
  /** column name */
  api_secret_encrypted = 'api_secret_encrypted',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  last_sync = 'last_sync',
  /** column name */
  meter_integration_type_id = 'meter_integration_type_id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "meter_integration_type" */
export enum meter_integration_type_constraint {
  /** unique or primary key constraint on columns "id" */
  meter_integration_type_pkey = 'meter_integration_type_pkey',
}

export enum meter_integration_type_enum {
  BASCLOUD = 'BASCLOUD',
}

/** select columns of table "meter_integration_type" */
export enum meter_integration_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "meter_integration_type" */
export enum meter_integration_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "meter_integration" */
export enum meter_integration_update_column {
  /** column name */
  api_key = 'api_key',
  /** column name */
  api_metadata = 'api_metadata',
  /** column name */
  api_secret_encrypted = 'api_secret_encrypted',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  last_sync = 'last_sync',
  /** column name */
  meter_integration_type_id = 'meter_integration_type_id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** select columns of table "meter" */
export enum meter_select_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  consumption_frequency_type_id = 'consumption_frequency_type_id',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  consumption_unit_id = 'consumption_unit_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  external_id = 'external_id',
  /** column name */
  id = 'id',
  /** column name */
  last_poll = 'last_poll',
  /** column name */
  meter_integration_building_id = 'meter_integration_building_id',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "meter" */
export enum meter_update_column {
  /** column name */
  area_id = 'area_id',
  /** column name */
  consumption_frequency_type_id = 'consumption_frequency_type_id',
  /** column name */
  consumption_type_id = 'consumption_type_id',
  /** column name */
  consumption_unit_id = 'consumption_unit_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  external_id = 'external_id',
  /** column name */
  id = 'id',
  /** column name */
  last_poll = 'last_poll',
  /** column name */
  meter_integration_building_id = 'meter_integration_building_id',
  /** column name */
  sync_state_id = 'sync_state_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "notification" */
export enum notification_constraint {
  /** unique or primary key constraint on columns "id" */
  notification_pkey = 'notification_pkey',
}

/** select columns of table "notification" */
export enum notification_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  read = 'read',
  /** column name */
  template_id = 'template_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
}

/** select "notification_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification" */
export enum notification_select_column_notification_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  read = 'read',
}

/** select "notification_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification" */
export enum notification_select_column_notification_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  read = 'read',
}

/** unique or primary key constraints on table "notification_template" */
export enum notification_template_constraint {
  /** unique or primary key constraint on columns "id" */
  notification_template_pkey = 'notification_template_pkey',
}

/** select columns of table "notification_template" */
export enum notification_template_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  href = 'href',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "notification_template" */
export enum notification_template_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  href = 'href',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "notification_type" */
export enum notification_type_constraint {
  /** unique or primary key constraint on columns "id" */
  notification_type_pkey = 'notification_type_pkey',
}

export enum notification_type_enum {
  /** notification type for all users */
  GLOBAL_NOTIFICATION = 'GLOBAL_NOTIFICATION',
  /** notification type specific to a whole organisation */
  ORG_NOTIFICATION = 'ORG_NOTIFICATION',
  /** notification type specific to a single user */
  USER_NOTIFICATION = 'USER_NOTIFICATION',
}

/** select columns of table "notification_type" */
export enum notification_type_select_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
}

/** update columns of table "notification_type" */
export enum notification_type_update_column {
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
}

/** update columns of table "notification" */
export enum notification_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  read = 'read',
  /** column name */
  template_id = 'template_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
}

/** unique or primary key constraints on table "operation_model_type" */
export enum operation_model_type_constraint {
  /** unique or primary key constraint on columns "id" */
  operation_model_type_pkey = 'operation_model_type_pkey',
}

export enum operation_model_type_enum {
  RENTAL_MODEL = 'RENTAL_MODEL',
  SELF_USE_MODEL = 'SELF_USE_MODEL',
  TENANT_CONTROLLED_MODEL = 'TENANT_CONTROLLED_MODEL',
}

/** select columns of table "operation_model_type" */
export enum operation_model_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "operation_model_type" */
export enum operation_model_type_update_column {
  /** column name */
  id = 'id',
}

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last',
}

/** unique or primary key constraints on table "org" */
export enum org_constraint {
  /** unique or primary key constraint on columns "logo_file_id" */
  org_logo_file_id_key = 'org_logo_file_id_key',
  /** unique or primary key constraint on columns "id" */
  org_pkey = 'org_pkey',
}

/** unique or primary key constraints on table "org_preference" */
export enum org_preference_constraint {
  /** unique or primary key constraint on columns "org_id" */
  org_preference_org_id_key = 'org_preference_org_id_key',
  /** unique or primary key constraint on columns "id" */
  org_preference_pkey = 'org_preference_pkey',
}

/** select columns of table "org_preference" */
export enum org_preference_select_column {
  /** column name */
  economic_unit_enabled = 'economic_unit_enabled',
  /** column name */
  emission_factor_type_id = 'emission_factor_type_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
}

/** update columns of table "org_preference" */
export enum org_preference_update_column {
  /** column name */
  economic_unit_enabled = 'economic_unit_enabled',
  /** column name */
  emission_factor_type_id = 'emission_factor_type_id',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
}

/** select columns of table "org" */
export enum org_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  logo_file_id = 'logo_file_id',
  /** column name */
  name = 'name',
  /** column name */
  status_type_id = 'status_type_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "org_status_type" */
export enum org_status_type_constraint {
  /** unique or primary key constraint on columns "id" */
  org_status_type_pkey = 'org_status_type_pkey',
}

export enum org_status_type_enum {
  ACTIVE = 'ACTIVE',
  DEMO = 'DEMO',
  INACTIVE = 'INACTIVE',
}

/** select columns of table "org_status_type" */
export enum org_status_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "org_status_type" */
export enum org_status_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "org" */
export enum org_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  logo_file_id = 'logo_file_id',
  /** column name */
  name = 'name',
  /** column name */
  status_type_id = 'status_type_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "portfolio" */
export enum portfolio_constraint {
  /** unique or primary key constraint on columns "id" */
  portfolio_pkey = 'portfolio_pkey',
}

/** select columns of table "portfolio" */
export enum portfolio_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor_type_id = 'emission_factor_type_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "portfolio" */
export enum portfolio_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  emission_factor_type_id = 'emission_factor_type_id',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  updated_at = 'updated_at',
}

/** Status of the queue */
export enum queue_status_enum {
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
}

/** unique or primary key constraints on table "renovation" */
export enum renovation_constraint {
  /** unique or primary key constraint on columns "id" */
  renovation_pkey = 'renovation_pkey',
}

/** select columns of table "renovation_energy_consumer" */
export enum renovation_energy_consumer_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation_energy_distribution" */
export enum renovation_energy_distribution_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation_energy_producer" */
export enum renovation_energy_producer_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation_energy_storage" */
export enum renovation_energy_storage_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation_energy_system_route" */
export enum renovation_energy_system_route_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation_envelope" */
export enum renovation_envelope_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
}

/** select columns of table "renovation" */
export enum renovation_select_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  cost_total = 'cost_total',
  /** column name */
  cost_total_custom = 'cost_total_custom',
  /** column name */
  cost_total_predium = 'cost_total_predium',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
  /** column name */
  use_custom_cost = 'use_custom_cost',
}

/** select "renovation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "renovation" */
export enum renovation_select_column_renovation_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  use_custom_cost = 'use_custom_cost',
}

/** select "renovation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "renovation" */
export enum renovation_select_column_renovation_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  use_custom_cost = 'use_custom_cost',
}

/** unique or primary key constraints on table "renovation_type" */
export enum renovation_type_constraint {
  /** unique or primary key constraint on columns "id" */
  renovation_type_pkey = 'renovation_type_pkey',
}

export enum renovation_type_enum {
  ENERGY_CONSUMER = 'ENERGY_CONSUMER',
  ENERGY_DISTRIBUTION = 'ENERGY_DISTRIBUTION',
  ENERGY_STORAGE = 'ENERGY_STORAGE',
  ENERGY_SYSTEM_ROUTE = 'ENERGY_SYSTEM_ROUTE',
  ENVELOPE = 'ENVELOPE',
  HYDRAULIC_BALANCING = 'HYDRAULIC_BALANCING',
  SOLAR_PLANT = 'SOLAR_PLANT',
}

/** select columns of table "renovation_type" */
export enum renovation_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "renovation_type" */
export enum renovation_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "renovation" */
export enum renovation_update_column {
  /** column name */
  action_id = 'action_id',
  /** column name */
  cost_total_custom = 'cost_total_custom',
  /** column name */
  cost_total_predium = 'cost_total_predium',
  /** column name */
  id = 'id',
  /** column name */
  new_entity_id = 'new_entity_id',
  /** column name */
  old_entity_id = 'old_entity_id',
  /** column name */
  order = 'order',
  /** column name */
  renovation_type_id = 'renovation_type_id',
  /** column name */
  use_custom_cost = 'use_custom_cost',
}

/** unique or primary key constraints on table "report_answer_autofill_option" */
export enum report_answer_autofill_option_constraint {
  /** unique or primary key constraint on columns "report_answer_id", "report_template_question_option_id" */
  report_answer_autofill_option_pkey = 'report_answer_autofill_option_pkey',
}

/** select columns of table "report_answer_autofill_option" */
export enum report_answer_autofill_option_select_column {
  /** column name */
  report_answer_id = 'report_answer_id',
  /** column name */
  report_template_question_option_id = 'report_template_question_option_id',
}

/** update columns of table "report_answer_autofill_option" */
export enum report_answer_autofill_option_update_column {
  /** column name */
  report_answer_id = 'report_answer_id',
  /** column name */
  report_template_question_option_id = 'report_template_question_option_id',
}

/** unique or primary key constraints on table "report_answer_chosen_option" */
export enum report_answer_chosen_option_constraint {
  /** unique or primary key constraint on columns "report_answer_id", "report_template_question_option_id" */
  report_answer_chosen_option_pkey = 'report_answer_chosen_option_pkey',
}

/** select columns of table "report_answer_chosen_option" */
export enum report_answer_chosen_option_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  report_answer_id = 'report_answer_id',
  /** column name */
  report_template_question_option_id = 'report_template_question_option_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "report_answer_chosen_option" */
export enum report_answer_chosen_option_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  report_answer_id = 'report_answer_id',
  /** column name */
  report_template_question_option_id = 'report_template_question_option_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "report_answer" */
export enum report_answer_constraint {
  /** unique or primary key constraint on columns "id" */
  report_answer_pkey = 'report_answer_pkey',
}

/** select columns of table "report_answer" */
export enum report_answer_select_column {
  /** column name */
  autofill = 'autofill',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_question_id = 'report_question_id',
  /** column name */
  state = 'state',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "report_answer_state" */
export enum report_answer_state_constraint {
  /** unique or primary key constraint on columns "id" */
  report_answer_state_pkey = 'report_answer_state_pkey',
}

export enum report_answer_state_enum {
  ANSWERED = 'ANSWERED',
  REVIEWED = 'REVIEWED',
  UNANSWERED = 'UNANSWERED',
}

/** select columns of table "report_answer_state" */
export enum report_answer_state_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_answer_state" */
export enum report_answer_state_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_answer" */
export enum report_answer_update_column {
  /** column name */
  autofill = 'autofill',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_question_id = 'report_question_id',
  /** column name */
  state = 'state',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "report" */
export enum report_constraint {
  /** unique or primary key constraint on columns "id" */
  report_instance_pkey = 'report_instance_pkey',
}

/** unique or primary key constraints on table "report_evidence" */
export enum report_evidence_constraint {
  /** unique or primary key constraint on columns "id" */
  report_evidence_pkey = 'report_evidence_pkey',
}

/** select columns of table "report_evidence" */
export enum report_evidence_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
}

/** update columns of table "report_evidence" */
export enum report_evidence_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  file_id = 'file_id',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
}

/** unique or primary key constraints on table "report_question_evidence" */
export enum report_question_evidence_constraint {
  /** unique or primary key constraint on columns "id" */
  report_question_evidence_pkey = 'report_question_evidence_pkey',
}

/** select columns of table "report_question_evidence" */
export enum report_question_evidence_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  report_evidence_id = 'report_evidence_id',
  /** column name */
  report_template_question_id = 'report_template_question_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "report_question_evidence" */
export enum report_question_evidence_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  report_evidence_id = 'report_evidence_id',
  /** column name */
  report_template_question_id = 'report_template_question_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "report_score" */
export enum report_score_constraint {
  /** unique or primary key constraint on columns "id" */
  report_score_pkey = 'report_score_pkey',
  /** unique or primary key constraint on columns "report_id", "report_template_score_id" */
  report_score_report_template_score_id_report_id_key = 'report_score_report_template_score_id_report_id_key',
}

/** select columns of table "report_score" */
export enum report_score_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_template_score_id = 'report_template_score_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** update columns of table "report_score" */
export enum report_score_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_template_score_id = 'report_template_score_id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "report_section_progress" */
export enum report_section_progress_constraint {
  /** unique or primary key constraint on columns "id" */
  report_section_progress_pkey = 'report_section_progress_pkey',
}

/** select columns of table "report_section_progress" */
export enum report_section_progress_select_column {
  /** column name */
  id = 'id',
  /** column name */
  progress = 'progress',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_template_section_id = 'report_template_section_id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** update columns of table "report_section_progress" */
export enum report_section_progress_update_column {
  /** column name */
  id = 'id',
  /** column name */
  progress = 'progress',
  /** column name */
  report_id = 'report_id',
  /** column name */
  report_template_section_id = 'report_template_section_id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** select columns of table "report" */
export enum report_select_column {
  /** column name */
  completed = 'completed',
  /** column name */
  created_at = 'created_at',
  /** column name */
  due_date = 'due_date',
  /** column name */
  end_date = 'end_date',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  progress = 'progress',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  start_date = 'start_date',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  version = 'version',
}

/** unique or primary key constraints on table "report_sub_building" */
export enum report_sub_building_constraint {
  /** unique or primary key constraint on columns "report_id", "sub_building_id" */
  report_sub_building_pkey = 'report_sub_building_pkey',
}

/** select columns of table "report_sub_building" */
export enum report_sub_building_select_column {
  /** column name */
  report_id = 'report_id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** update columns of table "report_sub_building" */
export enum report_sub_building_update_column {
  /** column name */
  report_id = 'report_id',
  /** column name */
  sub_building_id = 'sub_building_id',
}

/** unique or primary key constraints on table "report_template" */
export enum report_template_constraint {
  /** unique or primary key constraint on columns "id" */
  report_pkey = 'report_pkey',
}

/** unique or primary key constraints on table "report_template_question" */
export enum report_template_question_constraint {
  /** unique or primary key constraint on columns "id" */
  report_question_pkey = 'report_question_pkey',
}

/** unique or primary key constraints on table "report_template_question_option" */
export enum report_template_question_option_constraint {
  /** unique or primary key constraint on columns "id" */
  report_question_option_pkey = 'report_question_option_pkey',
  /** unique or primary key constraint on columns "order", "report_template_question_id" */
  report_question_option_question_id_order_key = 'report_question_option_question_id_order_key',
  /** unique or primary key constraint on columns "report_template_question_id", "value" */
  report_question_option_question_id_value_key = 'report_question_option_question_id_value_key',
}

/** select columns of table "report_template_question_option" */
export enum report_template_question_option_select_column {
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  order = 'order',
  /** column name */
  report_template_question_id = 'report_template_question_id',
  /** column name */
  value = 'value',
}

/** select "report_template_question_option_aggregate_bool_exp_avg_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_corr_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_max_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_min_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_sum_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_option_aggregate_bool_exp_var_samp_arguments_columns" columns of table "report_template_question_option" */
export enum report_template_question_option_select_column_report_template_question_option_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** update columns of table "report_template_question_option" */
export enum report_template_question_option_update_column {
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  order = 'order',
  /** column name */
  report_template_question_id = 'report_template_question_id',
  /** column name */
  value = 'value',
}

/** select columns of table "report_template_question" */
export enum report_template_question_select_column {
  /** column name */
  autofill = 'autofill',
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  evidence_required = 'evidence_required',
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  metadata = 'metadata',
  /** column name */
  order = 'order',
  /** column name */
  parent_option_id = 'parent_option_id',
  /** column name */
  parent_section_id = 'parent_section_id',
  /** column name */
  question = 'question',
  /** column name */
  reference = 'reference',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** select "report_template_question_aggregate_bool_exp_avg_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_avg_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  evidence_required = 'evidence_required',
}

/** select "report_template_question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  evidence_required = 'evidence_required',
}

/** select "report_template_question_aggregate_bool_exp_corr_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_corr_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_covar_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_max_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_max_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_min_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_min_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_stddev_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_sum_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_sum_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** select "report_template_question_aggregate_bool_exp_var_samp_arguments_columns" columns of table "report_template_question" */
export enum report_template_question_select_column_report_template_question_aggregate_bool_exp_var_samp_arguments_columns {
  /** column name */
  max_score = 'max_score',
}

/** unique or primary key constraints on table "report_template_question_type" */
export enum report_template_question_type_constraint {
  /** unique or primary key constraint on columns "id" */
  report_question_type_pkey = 'report_question_type_pkey',
}

export enum report_template_question_type_enum {
  DATE = 'DATE',
  FLOAT = 'FLOAT',
  INTEGER = 'INTEGER',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  PERCENTAGE = 'PERCENTAGE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  TEXT = 'TEXT',
}

/** select columns of table "report_template_question_type" */
export enum report_template_question_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_template_question_type" */
export enum report_template_question_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_template_question" */
export enum report_template_question_update_column {
  /** column name */
  autofill = 'autofill',
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  evidence_required = 'evidence_required',
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  metadata = 'metadata',
  /** column name */
  order = 'order',
  /** column name */
  parent_option_id = 'parent_option_id',
  /** column name */
  parent_section_id = 'parent_section_id',
  /** column name */
  question = 'question',
  /** column name */
  reference = 'reference',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "report_template_score" */
export enum report_template_score_constraint {
  /** unique or primary key constraint on columns "report_template_id", "name" */
  report_template_score_name_report_template_id_key = 'report_template_score_name_report_template_id_key',
  /** unique or primary key constraint on columns "id" */
  report_template_score_pkey = 'report_template_score_pkey',
  /** unique or primary key constraint on columns "reference", "report_template_id" */
  report_template_score_reference_report_template_id_key = 'report_template_score_reference_report_template_id_key',
  /** unique or primary key constraint on columns "order", "report_template_id" */
  report_template_score_report_template_id_order_key = 'report_template_score_report_template_id_order_key',
}

/** unique or primary key constraints on table "report_template_score_display_type" */
export enum report_template_score_display_type_constraint {
  /** unique or primary key constraint on columns "id" */
  report_template_score_display_type_pkey = 'report_template_score_display_type_pkey',
}

export enum report_template_score_display_type_enum {
  BOOLEAN = 'BOOLEAN',
  PERCENTAGE = 'PERCENTAGE',
  POINTS = 'POINTS',
}

/** select columns of table "report_template_score_display_type" */
export enum report_template_score_display_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_template_score_display_type" */
export enum report_template_score_display_type_update_column {
  /** column name */
  id = 'id',
}

/** select columns of table "report_template_score" */
export enum report_template_score_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  display = 'display',
  /** column name */
  display_type = 'display_type',
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  name = 'name',
  /** column name */
  order = 'order',
  /** column name */
  reference = 'reference',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  resolver = 'resolver',
  /** column name */
  updated_at = 'updated_at',
}

/** select "report_template_score_aggregate_bool_exp_bool_and_arguments_columns" columns of table "report_template_score" */
export enum report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  display = 'display',
}

/** select "report_template_score_aggregate_bool_exp_bool_or_arguments_columns" columns of table "report_template_score" */
export enum report_template_score_select_column_report_template_score_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  display = 'display',
}

/** update columns of table "report_template_score" */
export enum report_template_score_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  display = 'display',
  /** column name */
  display_type = 'display_type',
  /** column name */
  id = 'id',
  /** column name */
  max_score = 'max_score',
  /** column name */
  name = 'name',
  /** column name */
  order = 'order',
  /** column name */
  reference = 'reference',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  resolver = 'resolver',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "report_template_section" */
export enum report_template_section_constraint {
  /** unique or primary key constraint on columns "id" */
  report_section_pkey = 'report_section_pkey',
}

/** select columns of table "report_template_section" */
export enum report_template_section_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  order = 'order',
  /** column name */
  parent_section_id = 'parent_section_id',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  target = 'target',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "report_template_section_target" */
export enum report_template_section_target_constraint {
  /** unique or primary key constraint on columns "id" */
  report_question_target_pkey = 'report_question_target_pkey',
}

export enum report_template_section_target_enum {
  ORG = 'ORG',
  PORTFOLIO = 'PORTFOLIO',
  SUB_BUILDING = 'SUB_BUILDING',
}

/** select columns of table "report_template_section_target" */
export enum report_template_section_target_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_template_section_target" */
export enum report_template_section_target_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "report_template_section" */
export enum report_template_section_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  order = 'order',
  /** column name */
  parent_section_id = 'parent_section_id',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  target = 'target',
  /** column name */
  updated_at = 'updated_at',
}

/** select columns of table "report_template" */
export enum report_template_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  excel_template_path = 'excel_template_path',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  single_sub_building = 'single_sub_building',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  version = 'version',
}

/** select columns of table "report_template_to_all_questions" */
export enum report_template_to_all_questions_select_column {
  /** column name */
  question_id = 'question_id',
  /** column name */
  report_template_id = 'report_template_id',
}

/** update columns of table "report_template" */
export enum report_template_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  excel_template_path = 'excel_template_path',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  single_sub_building = 'single_sub_building',
  /** column name */
  title = 'title',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  version = 'version',
}

/** update columns of table "report" */
export enum report_update_column {
  /** column name */
  completed = 'completed',
  /** column name */
  created_at = 'created_at',
  /** column name */
  due_date = 'due_date',
  /** column name */
  end_date = 'end_date',
  /** column name */
  id = 'id',
  /** column name */
  org_id = 'org_id',
  /** column name */
  progress = 'progress',
  /** column name */
  report_template_id = 'report_template_id',
  /** column name */
  responsible_user_id = 'responsible_user_id',
  /** column name */
  start_date = 'start_date',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  version = 'version',
}

/** unique or primary key constraints on table "role" */
export enum role_constraint {
  /** unique or primary key constraint on columns "id" */
  role_pkey = 'role_pkey',
}

export enum role_enum {
  API_READ = 'API_READ',
  API_WRITE = 'API_WRITE',
  ORG_ADMIN = 'ORG_ADMIN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER = 'USER',
}

/** select columns of table "role" */
export enum role_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "role" */
export enum role_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "roof_type" */
export enum roof_type_constraint {
  /** unique or primary key constraint on columns "id" */
  roof_type_pkey = 'roof_type_pkey',
}

/** select columns of table "roof_type" */
export enum roof_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "roof_type" */
export enum roof_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "scenario" */
export enum scenario_constraint {
  /** unique or primary key constraint on columns "id" */
  scenario_pkey = 'scenario_pkey',
}

/** select columns of table "scenario" */
export enum scenario_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "scenario" */
export enum scenario_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  owner_id = 'owner_id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "sub_building_class" */
export enum sub_building_class_constraint {
  /** unique or primary key constraint on columns "id" */
  sub_building_class_pkey = 'sub_building_class_pkey',
}

export enum sub_building_class_enum {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
}

/** select columns of table "sub_building_class" */
export enum sub_building_class_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "sub_building_class" */
export enum sub_building_class_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "sub_building" */
export enum sub_building_constraint {
  /** unique or primary key constraint on columns "id" */
  sub_building_pkey = 'sub_building_pkey',
}

/** select columns of table "sub_building" */
export enum sub_building_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_standard = 'energy_standard',
  /** column name */
  id = 'id',
  /** column name */
  operation_model = 'operation_model',
  /** column name */
  sub_building_class = 'sub_building_class',
  /** column name */
  subsidies_updated_at = 'subsidies_updated_at',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  units_commercial = 'units_commercial',
  /** column name */
  units_residential = 'units_residential',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_renovated = 'year_renovated',
}

/** unique or primary key constraints on table "sub_building_subsidy" */
export enum sub_building_subsidy_constraint {
  /** unique or primary key constraint on columns "id" */
  sub_building_subsidy_pkey = 'sub_building_subsidy_pkey',
  /** unique or primary key constraint on columns "sub_building_id", "subsidy_id" */
  sub_building_subsidy_sub_building_id_subsidy_id_key = 'sub_building_subsidy_sub_building_id_subsidy_id_key',
}

/** select columns of table "sub_building_subsidy" */
export enum sub_building_subsidy_select_column {
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  subsidy_id = 'subsidy_id',
}

/** update columns of table "sub_building_subsidy" */
export enum sub_building_subsidy_update_column {
  /** column name */
  id = 'id',
  /** column name */
  sub_building_id = 'sub_building_id',
  /** column name */
  subsidy_id = 'subsidy_id',
}

/** update columns of table "sub_building" */
export enum sub_building_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  energy_standard = 'energy_standard',
  /** column name */
  id = 'id',
  /** column name */
  operation_model = 'operation_model',
  /** column name */
  sub_building_class = 'sub_building_class',
  /** column name */
  subsidies_updated_at = 'subsidies_updated_at',
  /** column name */
  type_of_use_id = 'type_of_use_id',
  /** column name */
  units_commercial = 'units_commercial',
  /** column name */
  units_residential = 'units_residential',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  year_renovated = 'year_renovated',
}

/** unique or primary key constraints on table "subsidy_category_type" */
export enum subsidy_category_type_constraint {
  /** unique or primary key constraint on columns "id" */
  subsidy_category_type_pkey = 'subsidy_category_type_pkey',
}

export enum subsidy_category_type_enum {
  ENVELOPE_CEILING = 'ENVELOPE_CEILING',
  ENVELOPE_FLOOR = 'ENVELOPE_FLOOR',
  ENVELOPE_ROOF = 'ENVELOPE_ROOF',
  ENVELOPE_UNCLASSIFIED = 'ENVELOPE_UNCLASSIFIED',
  ENVELOPE_WALL = 'ENVELOPE_WALL',
  ENVELOPE_WINDOW = 'ENVELOPE_WINDOW',
  SYSTEMS_HEATING = 'SYSTEMS_HEATING',
  SYSTEMS_PV = 'SYSTEMS_PV',
  SYSTEMS_VENTILATION = 'SYSTEMS_VENTILATION',
}

/** select columns of table "subsidy_category_type" */
export enum subsidy_category_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "subsidy_category_type" */
export enum subsidy_category_type_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "subsidy" */
export enum subsidy_constraint {
  /** unique or primary key constraint on columns "id" */
  subsidy_pkey = 'subsidy_pkey',
  /** unique or primary key constraint on columns "provider", "provider_id" */
  subsidy_provider_provider_id_key = 'subsidy_provider_provider_id_key',
}

/** unique or primary key constraints on table "subsidy_region_type" */
export enum subsidy_region_type_constraint {
  /** unique or primary key constraint on columns "id" */
  subsidy_region_pkey = 'subsidy_region_pkey',
}

export enum subsidy_region_type_enum {
  FEDERAL = 'FEDERAL',
  LOCAL = 'LOCAL',
  OTHER = 'OTHER',
  STATE = 'STATE',
}

/** select columns of table "subsidy_region_type" */
export enum subsidy_region_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "subsidy_region_type" */
export enum subsidy_region_type_update_column {
  /** column name */
  id = 'id',
}

/** select columns of table "subsidy" */
export enum subsidy_select_column {
  /** column name */
  amount_description = 'amount_description',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  provider = 'provider',
  /** column name */
  provider_id = 'provider_id',
  /** column name */
  provider_updated_at = 'provider_updated_at',
  /** column name */
  region_type = 'region_type',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
}

/** unique or primary key constraints on table "subsidy_subsidy_category" */
export enum subsidy_subsidy_category_constraint {
  /** unique or primary key constraint on columns "id" */
  subsidy_subsidy_category_pkey = 'subsidy_subsidy_category_pkey',
  /** unique or primary key constraint on columns "subsidy_category_type", "subsidy_id" */
  subsidy_subsidy_category_subsidy_id_subsidy_category_type_key = 'subsidy_subsidy_category_subsidy_id_subsidy_category_type_key',
}

/** select columns of table "subsidy_subsidy_category" */
export enum subsidy_subsidy_category_select_column {
  /** column name */
  id = 'id',
  /** column name */
  subsidy_category_type = 'subsidy_category_type',
  /** column name */
  subsidy_id = 'subsidy_id',
}

/** update columns of table "subsidy_subsidy_category" */
export enum subsidy_subsidy_category_update_column {
  /** column name */
  id = 'id',
  /** column name */
  subsidy_category_type = 'subsidy_category_type',
  /** column name */
  subsidy_id = 'subsidy_id',
}

/** unique or primary key constraints on table "subsidy_type" */
export enum subsidy_type_constraint {
  /** unique or primary key constraint on columns "id" */
  subsidy_type_pkey = 'subsidy_type_pkey',
}

export enum subsidy_type_enum {
  BOND = 'BOND',
  GRANT = 'GRANT',
  LOAN = 'LOAN',
}

/** select columns of table "subsidy_type" */
export enum subsidy_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "subsidy_type" */
export enum subsidy_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "subsidy" */
export enum subsidy_update_column {
  /** column name */
  amount_description = 'amount_description',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  id = 'id',
  /** column name */
  provider = 'provider',
  /** column name */
  provider_id = 'provider_id',
  /** column name */
  provider_updated_at = 'provider_updated_at',
  /** column name */
  region_type = 'region_type',
  /** column name */
  title = 'title',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  url = 'url',
}

/** unique or primary key constraints on table "sync_state" */
export enum sync_state_constraint {
  /** unique or primary key constraint on columns "id" */
  sync_state_pkey = 'sync_state_pkey',
}

export enum sync_state_enum {
  IN_SYNC = 'IN_SYNC',
  OUT_OF_SYNC = 'OUT_OF_SYNC',
  SYNCING = 'SYNCING',
}

/** select columns of table "sync_state" */
export enum sync_state_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "sync_state" */
export enum sync_state_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "target_path" */
export enum target_path_constraint {
  /** unique or primary key constraint on columns "id" */
  target_path_pkey = 'target_path_pkey',
}

/** select columns of table "target_path" */
export enum target_path_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  target_path_template_id = 'target_path_template_id',
  /** column name */
  value = 'value',
  /** column name */
  value_total = 'value_total',
  /** column name */
  year = 'year',
}

/** unique or primary key constraints on table "target_path_template" */
export enum target_path_template_constraint {
  /** unique or primary key constraint on columns "id" */
  target_path_template_pkey = 'target_path_template_pkey',
}

/** select columns of table "target_path_template" */
export enum target_path_template_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  target_path_template_type_id = 'target_path_template_type_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "target_path_template_type" */
export enum target_path_template_type_constraint {
  /** unique or primary key constraint on columns "id" */
  target_path_template_type_pkey = 'target_path_template_type_pkey',
}

export enum target_path_template_type_enum {
  CO2 = 'CO2',
  ENERGY = 'ENERGY',
}

/** select columns of table "target_path_template_type" */
export enum target_path_template_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "target_path_template_type" */
export enum target_path_template_type_update_column {
  /** column name */
  id = 'id',
}

/** update columns of table "target_path_template" */
export enum target_path_template_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  target_path_template_type_id = 'target_path_template_type_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "target_path" */
export enum target_path_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  target_path_template_id = 'target_path_template_id',
  /** column name */
  value = 'value',
  /** column name */
  value_total = 'value_total',
  /** column name */
  year = 'year',
}

/** unique or primary key constraints on table "tax_bracket" */
export enum tax_bracket_constraint {
  /** unique or primary key constraint on columns "id" */
  tax_bracket_pkey = 'tax_bracket_pkey',
}

export enum tax_bracket_enum {
  _10_90 = '_10_90',
  _20_80 = '_20_80',
  _30_70 = '_30_70',
  _40_60 = '_40_60',
  _50_50 = '_50_50',
  _60_40 = '_60_40',
  _70_30 = '_70_30',
  _80_20 = '_80_20',
  _90_10 = '_90_10',
  _100_0 = '_100_0',
}

/** select columns of table "tax_bracket" */
export enum tax_bracket_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "tax_bracket" */
export enum tax_bracket_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "type_of_use" */
export enum type_of_use_constraint {
  /** unique or primary key constraint on columns "id" */
  type_of_use_pkey = 'type_of_use_pkey',
}

export enum type_of_use_enum {
  DISTRIBUTION_WAREHOUSE_COLD = 'DISTRIBUTION_WAREHOUSE_COLD',
  DISTRIBUTION_WAREHOUSE_WARM = 'DISTRIBUTION_WAREHOUSE_WARM',
  HEALTH_FACILITY = 'HEALTH_FACILITY',
  HOTEL = 'HOTEL',
  MFH = 'MFH',
  OFFICE = 'OFFICE',
  RETAIL = 'RETAIL',
  RETAIL_WAREHOUSE = 'RETAIL_WAREHOUSE',
  SFH = 'SFH',
  SHOPPING_CENTER = 'SHOPPING_CENTER',
}

/** select columns of table "type_of_use" */
export enum type_of_use_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "type_of_use" */
export enum type_of_use_update_column {
  /** column name */
  id = 'id',
}

/** unique or primary key constraints on table "user_building_permission" */
export enum user_building_permission_constraint {
  /** unique or primary key constraint on columns "id" */
  user_building_permission_pkey = 'user_building_permission_pkey',
  /** unique or primary key constraint on columns "user_id", "building_id" */
  user_building_permission_user_id_building_id_key = 'user_building_permission_user_id_building_id_key',
}

/** select columns of table "user_building_permission" */
export enum user_building_permission_select_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  read = 'read',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  write = 'write',
}

/** select "user_building_permission_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_building_permission" */
export enum user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  read = 'read',
  /** column name */
  write = 'write',
}

/** select "user_building_permission_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_building_permission" */
export enum user_building_permission_select_column_user_building_permission_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  read = 'read',
  /** column name */
  write = 'write',
}

/** update columns of table "user_building_permission" */
export enum user_building_permission_update_column {
  /** column name */
  building_id = 'building_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  read = 'read',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  write = 'write',
}

/** unique or primary key constraints on table "user" */
export enum user_constraint {
  /** unique or primary key constraint on columns "id" */
  users_pkey = 'users_pkey',
}

/** unique or primary key constraints on table "user_portfolio_permission" */
export enum user_portfolio_permission_constraint {
  /** unique or primary key constraint on columns "id" */
  user_portfolio_permission_pkey = 'user_portfolio_permission_pkey',
  /** unique or primary key constraint on columns "user_id", "portfolio_id" */
  user_portfolio_permission_user_id_portfolio_id_key = 'user_portfolio_permission_user_id_portfolio_id_key',
}

/** select columns of table "user_portfolio_permission" */
export enum user_portfolio_permission_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  read = 'read',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  write = 'write',
}

/** select "user_portfolio_permission_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_portfolio_permission" */
export enum user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_and_arguments_columns {
  /** column name */
  read = 'read',
  /** column name */
  write = 'write',
}

/** select "user_portfolio_permission_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_portfolio_permission" */
export enum user_portfolio_permission_select_column_user_portfolio_permission_aggregate_bool_exp_bool_or_arguments_columns {
  /** column name */
  read = 'read',
  /** column name */
  write = 'write',
}

/** update columns of table "user_portfolio_permission" */
export enum user_portfolio_permission_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  portfolio_id = 'portfolio_id',
  /** column name */
  read = 'read',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  write = 'write',
}

/** unique or primary key constraints on table "user_role" */
export enum user_role_constraint {
  /** unique or primary key constraint on columns "id" */
  user_role_pkey = 'user_role_pkey',
}

/** select columns of table "user_role" */
export enum user_role_select_column {
  /** column name */
  id = 'id',
  /** column name */
  role = 'role',
  /** column name */
  user_id = 'user_id',
}

/** update columns of table "user_role" */
export enum user_role_update_column {
  /** column name */
  id = 'id',
  /** column name */
  role = 'role',
  /** column name */
  user_id = 'user_id',
}

/** select columns of table "user" */
export enum user_select_column {
  /** column name */
  auth0_id = 'auth0_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  last_name = 'last_name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  updated_at = 'updated_at',
}

/** update columns of table "user" */
export enum user_update_column {
  /** column name */
  auth0_id = 'auth0_id',
  /** column name */
  created_at = 'created_at',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  language = 'language',
  /** column name */
  last_name = 'last_name',
  /** column name */
  org_id = 'org_id',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "window_type" */
export enum window_type_constraint {
  /** unique or primary key constraint on columns "id" */
  window_type_pkey = 'window_type_pkey',
}

/** select columns of table "window_type" */
export enum window_type_select_column {
  /** column name */
  id = 'id',
}

/** update columns of table "window_type" */
export enum window_type_update_column {
  /** column name */
  id = 'id',
}
