import { BuildingTabEnum } from '../pages/DataCollection/DataCollectionBuilding';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../sections/DataCollection/Buildings/TabsEnums';
import { booleanParam, enumParam } from './search_params/params';
import { InferParamsConfigType, convertToSearchParams } from './search_params/utils';
import { URLParam, joinPaths } from './utils';

const ROOT = '/';
const ROOTS_AUTH = '/auth';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: joinPaths(ROOTS_AUTH, 'login'),
  loginUnprotected: joinPaths(ROOTS_AUTH, 'login-unprotected'),
  register: joinPaths(ROOTS_AUTH, 'register'),
  registerUnprotected: joinPaths(ROOTS_AUTH, 'register-unprotected'),
  resetPassword: joinPaths(ROOTS_AUTH, 'reset-password'),
  verify: joinPaths(ROOTS_AUTH, 'verify'),
};

export const SEARCH_PARAMS = {
  report: {
    init: booleanParam,
  },
  dataCollection: {
    buildings: {
      value: enumParam(DataCollectionTabEnum),
      action: enumParam(DataCollectionTabActionsEnum),
    },
    building: {
      tab: enumParam(BuildingTabEnum),
    },
  },
};

type IDParam = { id: URLParam };

export const PATHS = {
  root: () => ROOT,
  notFound: () => joinPaths(PATHS.root(), '404'),
  esgAnalysis: {
    root: () => joinPaths(PATHS.root(), 'esg-analysis'),
    buildings: () => joinPaths(PATHS.esgAnalysis.root(), 'buildings'),
    building: (params: IDParam) => joinPaths(PATHS.esgAnalysis.buildings(), params.id),
    portfolio: () => joinPaths(PATHS.esgAnalysis.root(), 'portfolio'),
    economicUnits: () => joinPaths(PATHS.esgAnalysis.root(), 'economic-unit'),
  },
  actionPlanning: {
    root: () => joinPaths(PATHS.root(), 'action-planning'),
    scenarios: () => joinPaths(PATHS.actionPlanning.root(), 'scenarios'),
    scenario: (params: IDParam) => joinPaths(PATHS.actionPlanning.scenarios(), params.id),
    actionPlan: (params: IDParam & { scenarioId: URLParam }) =>
      joinPaths(PATHS.actionPlanning.scenario({ id: params.scenarioId }), 'action-plans', params.id),
  },
  reporting: {
    root: () => joinPaths(PATHS.root(), 'reporting'),
    reports: () => joinPaths(PATHS.reporting.root(), 'reports'),
    report: (params: IDParam, query?: InferParamsConfigType<typeof SEARCH_PARAMS.report>) =>
      joinPaths(PATHS.reporting.reports(), params.id, convertToSearchParams(SEARCH_PARAMS.report, query)),
  },
  dataCollection: {
    root: () => joinPaths(PATHS.root(), 'data-collection'),
    buildings: (_ = null, query?: InferParamsConfigType<typeof SEARCH_PARAMS.dataCollection.buildings>) =>
      joinPaths(
        PATHS.dataCollection.root(),
        'buildings',
        convertToSearchParams(SEARCH_PARAMS.dataCollection.buildings, query),
      ),
    building: (params: IDParam, query?: InferParamsConfigType<typeof SEARCH_PARAMS.dataCollection.building>) =>
      joinPaths(
        PATHS.dataCollection.buildings(),
        params.id,
        convertToSearchParams(SEARCH_PARAMS.dataCollection.building, query),
      ),
    energyCertificateDraft: (params: IDParam) => joinPaths(PATHS.dataCollection.buildings(), 'drafts', params.id),
    buildingConsumptionInvoice: (params: IDParam & { consumptionInvoiceId: URLParam }) =>
      joinPaths(PATHS.dataCollection.building({ id: params.id }), 'consumption-invoice', params.consumptionInvoiceId),
    buildingConsumptionInvoiceDraft: (params: IDParam & { draftId: URLParam }) =>
      joinPaths(PATHS.dataCollection.building({ id: params.id }), 'consumption-invoice', 'drafts', params.draftId),
    emissionCertificates: () => joinPaths(PATHS.dataCollection.root(), 'emission-certificates'),
    emissionCertificate: (params: IDParam) => joinPaths(PATHS.dataCollection.emissionCertificates(), params.id),
    emissionCertificateDrafts: () => joinPaths(PATHS.dataCollection.emissionCertificates(), 'drafts'),
    emissionCertificateDraft: (params: IDParam) =>
      joinPaths(PATHS.dataCollection.emissionCertificateDrafts(), params.id),
  },
  org: {
    root: () => joinPaths(PATHS.root(), 'org'),
    users: () => joinPaths(PATHS.org.root(), 'users'),
    user: (params: IDParam) => joinPaths(PATHS.org.users(), params.id),
    settings: () => joinPaths(PATHS.org.root(), 'settings'),
    portfolios: () => joinPaths(PATHS.org.root(), 'portfolios'),
  },
};
