import {
  Card,
  ClickAwayListener,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import Iconify from '../../../../../components/Iconify';
import { Equation } from '../Calculator/formulas';
import { Validation } from '../Validation/validations';

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  pointerEvents: 'none',
  [`& .${tooltipClasses.tooltip}`]: {
    /* HACK: make the original popper component invisible so we replace it with a card */
    backgroundColor: 'transparent',
    color: 'transparent',
    padding: 0,
    margin: 0,
    maxWidth: 380,
    pointerEvents: 'auto',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
});

type Props = PropsWithChildren<{
  labels: Validation['errorLabels'][];
  variant?: Validation['variant'] | Equation['type'];
  open: boolean;
  onClose: VoidFunction;
}>;

const CalculationTooltip = ({ labels, variant, open, children, onClose }: Props) => {
  // Close the tooltip when the component is unmounted
  // Why? when you change tab, click away's onclick doesn't get triggered
  // the component stays open, with this effect we close it when the component is unmounted
  useEffect(
    () => () => {
      open && onClose();
    },
    [onClose, open],
  );

  const label = labels[0];
  if (!variant) {
    return <>{children}</>;
  }

  const { icon, iconColor } = (
    {
      error: { icon: 'mdi:alert-circle', iconColor: 'error.main' },
      warning: { icon: 'mdi:alert', iconColor: 'warning.main' },
      APPROXIMATION_FORMULA: { icon: 'mdi:chart-timeline-variant-shimmer', iconColor: 'info.main' },
      CALCULATION_FORMULA: { icon: 'mdi:calculator', iconColor: 'primary.main' },
    } satisfies Record<typeof variant, { icon: string; iconColor: string }>
  )[variant];

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          onClose();
        }
      }}
    >
      <div>
        <WhiteTooltip
          open={open}
          onClose={onClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="left"
          arrow
          title={
            <Card>
              {label && (
                <Stack spacing={1} py={1}>
                  <Stack px={2} direction="row" alignItems="center" spacing={1}>
                    <Iconify color={iconColor} icon={icon} fontSize={16} />
                    <Typography variant="subtitle2">{label.title}</Typography>
                  </Stack>
                  {label.preBody && (
                    <Typography variant="caption" px={2} color="text.secondary">
                      {label.preBody}
                    </Typography>
                  )}
                  <Stack bgcolor="#F4F6F8" py={1} px={2} spacing={1}>
                    {labels.map(({ body }) =>
                      typeof body === 'string' ? <Typography variant="caption">{body}</Typography> : body,
                    )}
                  </Stack>
                  {label.postBody && (
                    <Typography variant="caption" px={2} color="text.secondary">
                      {label.postBody}
                    </Typography>
                  )}
                </Stack>
              )}
            </Card>
          }
        >
          <span>{children}</span>
        </WhiteTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CalculationTooltip;
