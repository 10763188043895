import { Box, CardContent, Divider, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { AnalyzeBuildingCrremAnalysisFragment, AnalyzeBuildingsCrremAnalysisFragment } from '@predium/client-graphql';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoTooltip from '../../../components/InfoTooltip';
import { DelayedLoading } from '../../../components/Loading';
import { PreLink } from '../../../components/presentations/PreLink';
import PreTabsAsToggle from '../../../components/presentations/PreTabsAsToggle';
import { Co2CrremAnalysis } from './CrremAnalysis/Co2CrremAnalysis';
import { EnergyCrremAnalysis } from './CrremAnalysis/EnergyCrremAnalysis';

export const esgPathGraphOptions = {
  chart: {
    toolbar: {
      offsetY: -40,
    },
  },
  stroke: {
    curve: 'straight',
    dashArray: [0, 4],
    width: [4, 2],
  },
  legend: {
    show: false,
  },
} as ApexCharts.ApexOptions;

type Props = {
  buildingLegendName: string;
  cumulativeExcessEmissions: number | undefined;
  selectedBenchmarkName: string;
  benchmarkCrremAnalysis:
    | {
        yearOfExcedance: number | null;
        strandingYear: number | null;
      }
    | undefined;
  crremAnalysis: AnalyzeBuildingCrremAnalysisFragment | AnalyzeBuildingsCrremAnalysisFragment | undefined | null;
  loading?: boolean;
  graphStartYear: number;
};

type PathGraphType = 'co2-graph' | 'energy-graph';

export function CrremAnalysis({
  buildingLegendName,
  selectedBenchmarkName,
  cumulativeExcessEmissions,
  graphStartYear,
  benchmarkCrremAnalysis,
  crremAnalysis,
  loading,
}: Props) {
  const { t } = useTranslation();
  const [pathGraphType, setPathGraphType] = useState<PathGraphType>('co2-graph');

  if (loading && crremAnalysis === undefined) {
    return <DelayedLoading />;
  }

  const link = (
    <PreLink
      link={{
        href: 'https://support.predium.de/artikel/crrem-pfad-und-stranding-zeitpunkt-verstehen',
        name: t('General_LearnMore'),
        external: true,
      }}
    />
  );

  const co2Path = crremAnalysis?.co2Path?.m2 ?? [];
  const co2TargetPath = crremAnalysis?.co2TargetPath ?? [];
  const energyPath = crremAnalysis?.energyPath?.m2 ?? [];
  const energyTargetPath = crremAnalysis?.energyTargetPath ?? [];
  const strandingYear = crremAnalysis?.strandingYear;
  const yearOfExcedance = crremAnalysis?.yearOfExcedance;

  const yearOfExcedanceChange =
    benchmarkCrremAnalysis?.yearOfExcedance && crremAnalysis?.yearOfExcedance
      ? crremAnalysis.yearOfExcedance - benchmarkCrremAnalysis?.yearOfExcedance
      : null;

  const strandingYearChange =
    crremAnalysis?.strandingYear && benchmarkCrremAnalysis?.strandingYear
      ? crremAnalysis.strandingYear - benchmarkCrremAnalysis?.strandingYear
      : null;

  const tabs = [
    {
      label: (
        <Box display="flex" alignItems="center">
          {t('General_CO2Path')}
          <InfoTooltip
            text={
              <Trans
                i18nKey={t('General_1_5C02PathGraph-tooltip')}
                components={{
                  bold: <strong />,
                  articleLink: link,
                }}
              />
            }
            maxwidth={600}
          />
        </Box>
      ),
      value: 'co2-graph',
    },
    {
      label: (
        <Box display="flex" alignItems="center">
          {t('General_EnergyPath')}
          <InfoTooltip
            text={
              <Trans
                i18nKey={t('General_1_5EnergyPathGraph-tooltip')}
                components={{
                  bold: <strong />,
                  articleLink: link,
                }}
              />
            }
            maxwidth={600}
          />
        </Box>
      ),
      value: 'energy-graph',
    },
  ];

  return (
    <Card>
      <Stack direction="row" alignItems="center" px={3} py={1}>
        <Stack mr={3} spacing={0} direction="row" alignItems="center">
          <Typography variant="h5">{t('EsgAnalysis_CrremAnalysis-Header')}</Typography>
          <Typography ml={2} variant="caption" color="grey.600">
            {t('EsgAnalysis_CrremAnalysis-SubTitle')}
          </Typography>
        </Stack>
        <PreTabsAsToggle
          value={pathGraphType}
          tabs={tabs}
          onChange={(_, newValue) => setPathGraphType(newValue as PathGraphType)}
        />
      </Stack>
      <Divider />
      <CardContent sx={{ p: 3 }}>
        {pathGraphType === 'co2-graph' ? (
          <Co2CrremAnalysis
            co2Path={co2Path}
            co2TargetPath={co2TargetPath}
            buildingLegendName={buildingLegendName}
            strandingYear={strandingYear}
            cumulativeExcessEmissions={cumulativeExcessEmissions}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              strandingYearChange,
            }}
          />
        ) : (
          <EnergyCrremAnalysis
            energyPath={energyPath}
            energyTargetPath={energyTargetPath}
            buildingLegendName={buildingLegendName}
            yearOfExcedance={yearOfExcedance}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              yearOfExcedanceChange,
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
