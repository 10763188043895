import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  consumption_frequency_type_enum,
  DataCollectionInsertConsumptionMutationVariables,
  DataCollectionInsertConsumptionWasteDetailsDraftMutation,
  DataCollectionInsertConsumptionWasteDetailsMutation,
  DataCollectionSubBuildingConsumptionDraftFragment,
} from '@predium/client-graphql';
import { convertStandardUnitsToUserInput, convertUserInputToStandardUnits } from '@predium/client-lookup';
import { consumption_source_type_enum, consumption_unit_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { FormProvider } from '../../../../components/hook-form';
import {
  DELETE_SINGLE_WASTE_DETAILS_DRAFT,
  INSERT_SINGLE_CONSUMPTION_DRAFT,
  INSERT_SINGLE_WASTE_DETAILS_DRAFT,
  UPDATE_SINGLE_CONSUMPTION_DRAFT,
  UPDATE_SINGLE_WASTE_DETAILS_DRAFT,
} from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import useSessionData from '../../../../hooks/useSessionData';
import {
  constructConsumptionValidationSchema,
  ConsumptionWasteDetailsFormData,
  SingleConsumptionFormData,
} from './SubBuildingSingleConsumptionForm';

export type SingleConsumptionDraftFormData = {
  // If undefined the draft doesn't exist yet,
  // id?: number;
  buildingId: number;

  consumptionDrafts: {
    // TRASH CONSUMPTION ONLY
    waste?: Partial<ConsumptionWasteDetailsFormData>;
  } & Partial<SingleConsumptionFormData['consumptionDrafts'][number]>[];
} & Omit<Partial<SingleConsumptionFormData>, 'buildingId'>;

export type SingleConsumptionDraftFormValueProps = SingleConsumptionDraftFormData;

type WasteDraftResult =
  DataCollectionInsertConsumptionWasteDetailsDraftMutation['insert_consumption_waste_details_draft_one'];

export type WasteResult = DataCollectionInsertConsumptionWasteDetailsMutation['insert_consumption_waste_details_one'];

export type OnCreateSingleConsumption = (consumptionDraftToCreate: {
  consumptionDraft: DataCollectionInsertConsumptionMutationVariables;
  wasteDetailsDraft: WasteResult | undefined;
}) => void;

type Props = {
  buildingId: number;
  subBuildingId: number;
  sourceTypeId: consumption_source_type_enum;
  consumptionDraftData: DataCollectionSubBuildingConsumptionDraftFragment | null;
  setConsumptionDraftData: React.Dispatch<
    React.SetStateAction<DataCollectionSubBuildingConsumptionDraftFragment | null>
  >;
  onCreateSingleConsumption: OnCreateSingleConsumption;
  renderChildren: (
    submitForm: () => Promise<{
      consumptionDraft: DataCollectionInsertConsumptionMutationVariables;
      wasteDetailsDraft: WasteResult | undefined;
    }>,
    errors: Partial<FieldErrorsImpl<SingleConsumptionDraftFormValueProps>>,
  ) => JSX.Element;
};

function SubBuildingSingleConsumptionDraftForm({
  buildingId,
  subBuildingId,
  sourceTypeId,
  consumptionDraftData,
  setConsumptionDraftData,
  onCreateSingleConsumption,
  renderChildren,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user, org } = useSessionData();
  const { trackEvent } = usePosthogTracking();

  const [createConsumptionDraft] = useMutation(INSERT_SINGLE_CONSUMPTION_DRAFT, {
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_SaveChanges-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSaved'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    refetchQueries: [GET_BUILDING],
  });
  const [createWasteDetailsDraft] = useMutation(INSERT_SINGLE_WASTE_DETAILS_DRAFT);
  const [saveConsumptionDraft] = useMutation(UPDATE_SINGLE_CONSUMPTION_DRAFT, {
    onError: () => {
      enqueueSnackbar(t('DataCollectionSubBuildingConsumptionDraft_SaveChanges-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSaved'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });
  const [updateWasteDetailsDraft] = useMutation(UPDATE_SINGLE_WASTE_DETAILS_DRAFT);
  const [removeWasteDetailsDraft] = useMutation(DELETE_SINGLE_WASTE_DETAILS_DRAFT);

  const DataSchema = constructConsumptionValidationSchema({
    t,
  });

  const {
    id,
    // sub_building_id,
    area_id,
    from,
    to,
    provider,
    consumption_type_id,
    cost,
    energy_source_type_id,
    sub_type_id,
    value,
    display_unit_value,
    consumption_waste_details_draft,
  } = consumptionDraftData ?? {};

  const methods = useForm<SingleConsumptionDraftFormValueProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(DataSchema),
    shouldFocusError: false,
    defaultValues: {
      id,
      buildingId,
      // subBuildingId: sub_building_id,

      provider: provider ?? '',
      from: from ?? '',
      to: to ?? '',
      areaId: area_id ?? ('' as any),

      consumptionDrafts: [
        {
          id,
          consumptionTypeId: consumption_type_id ?? undefined,
          energySourceTypeId: energy_source_type_id ?? undefined,
          subTypeId: sub_type_id ?? undefined,
          value:
            value && display_unit_value
              ? parseFloat(
                  convertStandardUnitsToUserInput(
                    consumption_type_id!,
                    display_unit_value,
                    value,
                    (energy_source_type_id ?? sub_type_id)!,
                  ).toFixed(4),
                )
              : value ?? undefined,
          displayUnitValue: display_unit_value ?? ('' as consumption_unit_enum),
          cost: cost ?? undefined,

          // TRASH CONSUMPTION ONLY, OPTIONAL
          waste: consumption_waste_details_draft
            ? {
                id: consumption_waste_details_draft.id ?? undefined,
                volume:
                  consumption_waste_details_draft.volume && consumption_waste_details_draft.display_unit_volume
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id!,
                          consumption_waste_details_draft.display_unit_volume,
                          consumption_waste_details_draft.volume,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_details_draft.volume ?? undefined,
                displayUnitVolume: consumption_waste_details_draft.display_unit_volume ?? ('' as consumption_unit_enum),
                frequency:
                  consumption_waste_details_draft.frequency && consumption_waste_details_draft.display_unit_frequency
                    ? parseFloat(
                        convertStandardUnitsToUserInput(
                          consumption_type_id!,
                          consumption_waste_details_draft.display_unit_frequency,
                          consumption_waste_details_draft.frequency,
                          (energy_source_type_id ?? sub_type_id)!,
                        ).toFixed(4),
                      )
                    : consumption_waste_details_draft.frequency ?? undefined,
                displayUnitFrequency:
                  consumption_waste_details_draft.display_unit_frequency ?? ('' as consumption_frequency_type_enum),
                amount: consumption_waste_details_draft.amount ?? undefined,
                price: consumption_waste_details_draft.price ?? undefined,
              }
            : undefined,
        },
      ],
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = methods;

  /**
   *
   * @param data The forms native data after validation passes
   *
   * The following process is done for inserting a new consumption draft or analog to updating an existing draft.
   *
   * 1. If there is no draft data, create a new draft
   * 2. If there is draft data, update the draft
   * 3. If waste details are set create them if they don't exist, update them if they do
   * 4. Reset form with values from the network result
   * 5. Return the data to potentially create the real consumption
   *
   */
  const saveForm = async <Validated extends boolean = false>(
    data: SingleConsumptionDraftFormValueProps,
  ): Promise<{
    consumptionDraft: DataCollectionInsertConsumptionMutationVariables;
    wasteDetailsDraft: Validated extends true ? WasteResult : WasteDraftResult | undefined;
  }> => {
    const { from, to, provider, consumptionDrafts } = data;
    let { areaId } = data;
    // Damn MUI force me to set "" for a number select
    // @ts-ignore
    areaId = areaId !== '' ? areaId : undefined;

    const { consumptionTypeId, energySourceTypeId, subTypeId, value, displayUnitValue, cost, waste } =
      consumptionDrafts[0];

    // We use this to return the data to create the consumption and reset the form for either INSERT or UPDATE
    let returnData: (DataCollectionInsertConsumptionMutationVariables & { consumptionDraftId: number }) | undefined =
      undefined;

    let wasteId: number | undefined = undefined;

    // 1. If there is no draft data, create a new draft
    if (!data.id) {
      const { data: mutationResult } = await createConsumptionDraft({
        variables: {
          subBuildingId,

          areaId,
          // form control defaults to "" but we want to keep it null instead
          from: from || undefined,
          to: to || undefined,
          value:
            displayUnitValue && value
              ? convertUserInputToStandardUnits(
                  consumptionTypeId!,
                  displayUnitValue,
                  value,
                  (energySourceTypeId ?? subTypeId)!,
                )
              : value,
          displayUnitValue: displayUnitValue || undefined,
          cost,
          provider: provider || undefined,
          sourceTypeId,
          consumptionTypeId,
          energySourceTypeId,
          subTypeId,
          userId: user!.id,
        },
      });

      const {
        id,
        created_by_user_id,
        area_id,
        source_type_id,
        consumption_type_id,
        energy_source_type_id,
        sub_type_id,
        from: fromAfterInsert,
        to: toAfterInsert,
        value: valueAfterInsert,
        display_unit_value: displayUnitValueAfterInsert,
        cost: costAfterInsert,
        provider: providerAfterInsert,
      } = mutationResult!.insert_consumption_draft_one!;

      returnData = {
        consumptionDraftId: id,

        subBuildingId,
        areaId: area_id!,
        sourceTypeId: source_type_id,
        consumptionTypeId: consumption_type_id!,
        energySourceTypeId: energy_source_type_id!,
        subTypeId: sub_type_id!,
        userId: created_by_user_id!,
        from: fromAfterInsert as string,
        to: toAfterInsert!,
        value: valueAfterInsert!,
        displayUnitValue: displayUnitValueAfterInsert!,
        cost: costAfterInsert!,
        provider: providerAfterInsert!,
      };
      setConsumptionDraftData(mutationResult!.insert_consumption_draft_one!);
    }

    // 2. If there is draft data, update the draft
    else {
      trackEvent('BUILDING_SINGLE_CONSUMPTION_DRAFT_UPDATED', {
        org: org!.id,
        user: user!.id,
        building_id: buildingId,
        draft_id: data.id,
      });
      const { data: mutationResult } = await saveConsumptionDraft({
        variables: {
          consumptionDraftId: data.id!,
          areaId,
          from: from || undefined,
          to: to || undefined,
          value:
            displayUnitValue && value
              ? convertUserInputToStandardUnits(
                  consumptionTypeId!,
                  displayUnitValue,
                  value,
                  (energySourceTypeId ?? subTypeId)!,
                )
              : value,
          displayUnitValue: displayUnitValue || undefined,
          cost,
          provider: provider || undefined,
          consumptionTypeId,
          energySourceTypeId,
          subTypeId,
        },
      });

      const {
        id,
        area_id,
        source_type_id,
        consumption_type_id,
        created_by_user_id,
        energy_source_type_id,
        sub_type_id,
        from: fromAfterUpdate,
        to: toAfterUpdate,
        value: valueAfterUpdate,
        display_unit_value: displayUnitValueAfterUpdate,
        cost: costAfterUpdate,
        provider: providerAfterUpdate,
        consumption_waste_details_draft,
      } = mutationResult!.update_consumption_draft_by_pk!;

      wasteId = consumption_waste_details_draft?.id;

      returnData = {
        consumptionDraftId: id,

        subBuildingId,
        areaId: area_id!,
        sourceTypeId: source_type_id,
        consumptionTypeId: consumption_type_id!,
        userId: created_by_user_id!,
        from: fromAfterUpdate!,
        to: toAfterUpdate!,
        value: valueAfterUpdate!,
        displayUnitValue: displayUnitValueAfterUpdate!,
        cost: costAfterUpdate!,
        provider: providerAfterUpdate!,
        energySourceTypeId: energy_source_type_id!,
        subTypeId: sub_type_id!,
      };
    }

    setValue('id', returnData.consumptionDraftId);

    if (wasteId && !waste) {
      await removeWasteDetailsDraft({
        variables: {
          id: wasteId,
        },
      });

      return { consumptionDraft: returnData, wasteDetailsDraft: undefined };
    }

    if (!wasteId && !waste) {
      return { consumptionDraft: returnData, wasteDetailsDraft: undefined };
    }

    if (!waste) {
      throw new Error('Waste details are missing');
    }

    const wasteObject = {
      volume:
        waste.displayUnitVolume && waste.volume
          ? convertUserInputToStandardUnits(consumptionTypeId!, waste.displayUnitVolume, waste.volume, subTypeId!)
          : waste.volume,
      displayUnitVolume: waste.displayUnitVolume || undefined,
      frequency:
        waste.displayUnitFrequency && waste.frequency
          ? convertUserInputToStandardUnits(consumptionTypeId!, waste.displayUnitFrequency, waste.frequency, subTypeId!)
          : waste.frequency,
      displayUnitFrequency: waste.displayUnitFrequency || undefined,
      amount: waste.amount,
      price: waste.price,
    };

    if (!wasteId && waste) {
      const { data: createWasteDetailsData } = await createWasteDetailsDraft({
        variables: {
          consumptionDraftId: returnData.consumptionDraftId,
          ...wasteObject,
        },
      });

      const wasteDetailsDraft = createWasteDetailsData?.insert_consumption_waste_details_draft_one;

      if (!wasteDetailsDraft) {
        throw new Error('Failed to create waste details');
      }

      return {
        consumptionDraft: returnData,
        wasteDetailsDraft: wasteDetailsDraft as Validated extends true ? WasteResult : WasteDraftResult,
      };
    }

    const { data: updateWasteDetailsData } = await updateWasteDetailsDraft({
      variables: {
        id: wasteId!,
        ...wasteObject,
      },
    });

    const wasteDetailsDraft = updateWasteDetailsData?.update_consumption_waste_details_draft_by_pk;

    if (!wasteDetailsDraft) {
      throw new Error('Failed to update waste details');
    }

    return {
      consumptionDraft: returnData,
      wasteDetailsDraft: wasteDetailsDraft as Validated extends true ? WasteResult : WasteDraftResult,
    };
  };

  // Called when just the draft should be saved
  const submitForm = async () => {
    const values = getValues();

    return await saveForm(values);
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(async (data) => {
        const insertUpdateResponse = await saveForm<true>(data);

        onCreateSingleConsumption(insertUpdateResponse);
      })}
    >
      {/* @ts-ignore */}
      {renderChildren(submitForm, errors)}
    </FormProvider>
  );
}

export default SubBuildingSingleConsumptionDraftForm;
