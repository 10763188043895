/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  EnvelopeRenovationTemplates,
  InsulationMaterial,
  NotWindowDoorEnvelopeType,
  calculateUValue,
  getEnvelopeInsulationDefaults,
} from '@predium/client-lookup';
import { insulation_method_enum } from '@predium/enums';
import { cmToM } from '@predium/utils';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { CUSTOM, OtherFormValuesProps } from '../../BuildingEnvelope';
import useBuilding from '../../Context/useBuilding';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { FieldSectionTitle } from './EnvelopeUnitWrapper';

const fieldDefaultValues = ['', 0, '', 0];

export function EnvelopeInsulation({ data, index }: { data: OtherFormValuesProps; index: number }) {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();

  const { envelope_type: type, has_insulation } = data;

  const [availableValues, setAvailableValues] = useState<InsulationMaterial[]>();
  const [materialNames, setMaterialNames] = useState<string[]>();
  const { watch, setValue, getFieldState } = useFormContext();

  const fieldNamePrefix = `${type}[${index}]`;

  const uValueFieldName = `${fieldNamePrefix}.u_value`;

  const constructionThicknessFieldName = `${fieldNamePrefix}.base_construction_thickness`;
  const constructionLambdaFieldName = `${fieldNamePrefix}.base_construction_lambda`;

  const methodFieldName = `${fieldNamePrefix}.insulation_method`;
  const materialCustomFieldName = `${fieldNamePrefix}.insulation_material_custom`;
  const materialFieldName = `${fieldNamePrefix}.insulation_material_name`;
  const thicknessFieldName = `${fieldNamePrefix}.insulation_thickness`;
  const categoryFieldName = `${fieldNamePrefix}.insulation_material_category`;
  const lambdaFieldName = `${fieldNamePrefix}.insulation_lambda`;

  const insulationDeletedField = `${fieldNamePrefix}.insulation_deleted`;
  const insulationAddField = `${fieldNamePrefix}.has_insulation`;

  const materialName = watch(materialFieldName);
  const method = watch(methodFieldName);
  const insulationDeleted = watch(insulationDeletedField);
  const hasInsulation = has_insulation || watch(insulationAddField);
  const isMethodDirty = getFieldState(methodFieldName).isDirty;

  const baseConstructionThickness = watch(constructionThicknessFieldName);
  const baseConstructionLambda = watch(constructionLambdaFieldName);
  const insulationThickness = watch(thicknessFieldName);
  const insulationLambda = watch(lambdaFieldName);

  useEffect(() => {
    const newUValue = calculateUValue(
      type,
      cmToM(baseConstructionThickness),
      baseConstructionLambda,
      cmToM(insulationThickness),
      insulationLambda,
    );

    setValue(uValueFieldName, newUValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseConstructionThickness, baseConstructionLambda, insulationThickness, insulationLambda, materialName, method]);

  useEffect(() => {
    // @ts-ignore
    const values: InsulationMaterial[] = EnvelopeRenovationTemplates[type]?.[method] ?? [];
    if (values && values.length > 0) {
      setAvailableValues(values);
      const materialNames = values.map((value) => value.material_name);
      if (materialNames.length > 0) {
        //need to push the current db material name to list initially (which is not part of template material list)
        if (!isMethodDirty && materialName !== '' && !materialNames.includes(materialName) && materialName !== CUSTOM) {
          materialNames.push(materialName);
        }
        //reset material parameters if its not part of current selected insulation method
        if (isMethodDirty && !materialNames.includes(materialName) && materialName !== CUSTOM) {
          setFieldValues(fieldDefaultValues, true, false);
          setValue(materialFieldName, '', { shouldDirty: true });
        }
        setMaterialNames(materialNames);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.envelope_type, method, materialName]);

  const setFieldValues = (fieldValues: (string | number | null)[], shouldDirty: boolean, shouldValidate: boolean) => {
    const fieldNames = [materialCustomFieldName, thicknessFieldName, categoryFieldName, lambdaFieldName];
    fieldNames.forEach((fieldName, index) => {
      setValue(fieldName, fieldValues[index], { shouldDirty: shouldDirty, shouldValidate: shouldValidate });
    });
  };

  const setInsulationValues = (parameters: InsulationMaterial) => {
    setValue(materialFieldName, parameters.material_name, { shouldDirty: true, shouldValidate: true });

    const { insulation_thickness, insulation_material_category, insulation_lambda } = parameters;

    //convert template tickness value from m to cm
    const thickness = insulation_thickness ? parseFloat((insulation_thickness * 100).toFixed(2)) : 0;
    const fieldValues = [
      '', //material custom field
      thickness, //thickness field
      insulation_material_category, //category field
      insulation_lambda, //lambda Field
    ];

    //@ts-ignore
    setFieldValues(fieldValues, true, true);
  };

  const onInsulationMethodChange = (method: insulation_method_enum) => {
    const parameters = getEnvelopeInsulationDefaults(type as NotWindowDoorEnvelopeType, method);
    if (parameters) {
      setValue(methodFieldName, method, { shouldDirty: true, shouldValidate: true });
      setInsulationValues(parameters);
    }
  };

  const onMaterialNameChange = (materialName: string) => {
    if (materialName === CUSTOM) {
      setFieldValues(fieldDefaultValues, true, false);
      setValue(materialFieldName, CUSTOM, { shouldDirty: true });
    } else {
      if (availableValues && availableValues.length > 0) {
        const parameters = availableValues.find((material) => material?.material_name === materialName);
        if (parameters) {
          setInsulationValues(parameters);
        }
      }
    }
  };

  const deleteInsulation = () => {
    setValue(insulationDeletedField, true, { shouldDirty: true, shouldValidate: true });
    setFieldValues([null, null, null, null], true, true);
    setValue(materialFieldName, null, { shouldDirty: true, shouldValidate: true });
    setValue(methodFieldName, null, { shouldDirty: true, shouldValidate: true });
    setValue(insulationAddField, false, { shouldDirty: true });
  };

  const addInsulation = () => {
    setFieldValues(fieldDefaultValues, false, false);
    setValue(insulationDeletedField, false);
    setValue(materialFieldName, '');
    setValue(methodFieldName, '');
    setValue(insulationAddField, true, { shouldDirty: true });
  };

  return (
    <>
      <Grid item md={12}>
        <FieldSectionTitle
          title={t('General_Insulation')}
          action={
            <>
              {!insulationDeleted && hasInsulation && hasEditAccess ? (
                <Tooltip title={t('General_DeleteInsulation')}>
                  <IconButton
                    size="small"
                    onClick={() => deleteInsulation()}
                    sx={{ mt: -0.25, ml: 0.5, color: 'error.main' }}
                  >
                    <Iconify icon="mdi:trash-can-outline" width={18} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          }
        />
      </Grid>
      {!insulationDeleted && hasInsulation ? (
        <>
          <Grid item xs={12}>
            <EnvelopeUnitField
              title={t('General_TypeOfInsulation')}
              name={methodFieldName}
              //@ts-ignore
              id={data.id}
              fieldType="insulation_method"
              envelope_type={data.envelope_type}
              onInsulationMethodChange={onInsulationMethodChange}
            />
          </Grid>

          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_InsulationMaterial')}
              name={materialFieldName}
              //@ts-ignore
              id={data.id}
              fieldType="material_name"
              envelope_type={data.envelope_type}
              materialOptions={materialNames}
              onMaterialNameChange={onMaterialNameChange}
            />
          </Grid>

          {data.insulation_material_name === 'custom' && (
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_InsulationMaterial')}
                name={materialCustomFieldName}
                //@ts-ignore
                id={data.id}
                fieldType="text"
                envelope_type={data.envelope_type}
                placeholder={t('General_OwnInsulationMaterial')}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_InsulationThickness')}
              name={thicknessFieldName}
              //@ts-ignore
              id={data.id}
              unit="cm"
              fieldType="number"
              envelope_type={data.envelope_type}
            />
          </Grid>
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_MaterialProperty')}
              name={categoryFieldName}
              //@ts-ignore
              id={data.id}
              fieldType="insulation_material_category"
              envelope_type={data.envelope_type}
            />
          </Grid>
          <Grid item xs={6}>
            <EnvelopeUnitField
              title={t('General_ThermalConductivityLambda')}
              name={lambdaFieldName}
              //@ts-ignore
              id={data.id}
              unit="W/(m·K)"
              fieldType="number"
              envelope_type={data.envelope_type}
            />
          </Grid>
        </>
      ) : (
        <Stack sx={{ mt: 2 }}>
          <Typography variant="caption" gutterBottom>
            {t('General_NoInsulation')}
          </Typography>

          {hasEditAccess && (
            <Button
              variant="outlined"
              onClick={() => addInsulation()}
              startIcon={<Iconify icon="mdi:plus" width={20} />}
            >
              {t('General_AddInsulation')}
            </Button>
          )}
        </Stack>
      )}
    </>
  );
}
