import { envelope_type_enum, insulation_material_category_enum, insulation_method_enum } from '@predium/enums';
import isFinite from 'lodash/isFinite';

/**
 * We are assuming that every single window has an area of 1.2 m2 to simplify the calculation.
 */
export const INDIVIDUAL_WINDOW_AREA = 1.2;
/**
 * Correction factor for the cost calculation of insulation materials conducted by Simone and Maisa.
 */
export const CORRECTION_FACTOR = 0.035;
/**
 * Additional costs for the renovation of the building envelope and system.
 */
export const ADDITIONAL_COST_FACTOR = 0.15;

export type WindowDoorEnvelopeType = envelope_type_enum.WINDOW | envelope_type_enum.DOOR;

export type NotWindowDoorEnvelopeType = Exclude<envelope_type_enum, WindowDoorEnvelopeType>;

export type InsulationMaterial = {
  material_name: string;
  insulation_lambda: number;
  insulation_thickness: number;
  insulation_material_category: insulation_material_category_enum;
  cost_per_cm_m2?: number;
  preparation_cost_per_m2?: number;
};

export type DoorTemplate = {
  material_name: string;
  u_value: number;
  cost_per_m2: number;
};

export type WindowTemplate = DoorTemplate & {
  insulation_lambda: number; // which is g_value!
  glazing_factor: number;
};

/**
 * Insulation material templates which can be selected as templates by the user.
 */
export const enum InsulationMaterialTemplateTypes {
  Schaumglas = 'Schaumglas',
  Mineralschaumplatten = 'Mineralschaumplatten',
  Mineralwolle = 'Mineralwolle',
  Perlite = 'Perlite',
  Kalziumsilikatplatte = 'Kalziumsilikatplatte',
  Wärmedämmputz = 'Wärmedämmputz',
  Phenolharz_Hartschaum = 'Phenolharz_Hartschaum',
  PUR_Hartschaum = 'PUR_Hartschaum',
  PUR_Hartschaum_Alukaschiert = 'PUR_Hartschaum_Alukaschiert',
  PIR = 'PIR',
  EPS = 'EPS',
  XPS = 'XPS',
  Vakuumdaemmung = 'Vakuumdaemmung',
  Hanffaser = 'Hanffaser',
  Holzfaser = 'Holzfaser',
  Jute = 'Jute',
  Schafwolle = 'Schafwolle',
  Seegras = 'Seegras',
  Stroh = 'Stroh',
  Wiesengras = 'Wiesengras',
  Zellulose = 'Zellulose',
  Korkplatte = 'Korkplatte',
  Schilfplatten = 'Schilfplatten',
}

//properties of all materials in indexable object
export const InsulationMaterialTemplates: { [key in InsulationMaterialTemplateTypes]: InsulationMaterial } = {
  Schaumglas: {
    material_name: 'Schaumglas',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },
  Mineralschaumplatten: {
    material_name: 'Mineralschaumplatten',
    insulation_lambda: 0.045,
    insulation_thickness: 0.18,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },
  Mineralwolle: {
    material_name: 'Mineralwolle (Glaswolle / Steinwolle)',
    insulation_lambda: 0.035,
    insulation_thickness: 0.14,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },
  Perlite: {
    material_name: 'Perlite',
    insulation_lambda: 0.05,
    insulation_thickness: 0.2,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },
  Kalziumsilikatplatte: {
    material_name: 'Kalziumsilikatplatte',
    insulation_lambda: 0.07,
    insulation_thickness: 0.28,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },
  Wärmedämmputz: {
    material_name: 'Wärmedämmputz',
    insulation_lambda: 0.07,
    insulation_thickness: 0.03,
    insulation_material_category: insulation_material_category_enum.MINERAL,
  },

  //synthetic
  Phenolharz_Hartschaum: {
    material_name: 'Phenolharz-Hartschaum (PF)',
    insulation_lambda: 0.024,
    insulation_thickness: 0.09,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  PUR_Hartschaum: {
    material_name: 'Hartschaum (PUR)',
    insulation_lambda: 0.025,
    insulation_thickness: 0.1,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  PUR_Hartschaum_Alukaschiert: {
    material_name: 'Hartschaum (PUR) Alukaschiert',
    insulation_lambda: 0.025,
    insulation_thickness: 0.1,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  EPS: {
    material_name: 'Expandiertes Polystyrol (EPS)',
    insulation_lambda: 0.035,
    insulation_thickness: 0.14,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  XPS: {
    material_name: 'Extrudiertes Polystyrol (XPS)',
    insulation_lambda: 0.035,
    insulation_thickness: 0.14,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  PIR: {
    material_name: 'Polyisocyanurate (PIR)',
    insulation_lambda: 0.024,
    insulation_thickness: 0.12,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },
  Vakuumdaemmung: {
    material_name: 'Vakuumdämmung',
    insulation_lambda: 0.007,
    insulation_thickness: 0.04,
    insulation_material_category: insulation_material_category_enum.SYNTHETIC,
  },

  //renewable

  Hanffaser: {
    material_name: 'Hanffaser',
    insulation_lambda: 0.046,
    insulation_thickness: 0.18,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Holzfaser: {
    material_name: 'Holzfaserdämmstoff',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Jute: {
    material_name: 'Jute',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Schafwolle: {
    material_name: 'Schafwolle',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Seegras: {
    material_name: 'Seegras',
    insulation_lambda: 0.044,
    insulation_thickness: 0.18,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Stroh: {
    material_name: 'Stroh',
    insulation_lambda: 0.045,
    insulation_thickness: 0.18,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Wiesengras: {
    material_name: 'Wiesengras Zellulose',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Zellulose: {
    material_name: 'Zellulose',
    insulation_lambda: 0.04,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Korkplatte: {
    material_name: 'Korkplatte',
    insulation_lambda: 0.05,
    insulation_thickness: 0.16,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
  Schilfplatten: {
    material_name: 'Schilfplatten',
    insulation_lambda: 0.065,
    insulation_thickness: 0.26,
    insulation_material_category: insulation_material_category_enum.RENEWABLE,
  },
};

export const enum WindowTemplateTypes {
  DoubleInsulated = '2-fach Wärmeschutzverglasung',
  TripleInsulated = '3-fach Wärmeschutzverglasung',
  DoubleInsulatedPassive = '3-fach Wärmeschutzverglasung, Passivhaus geeignet',
}

export const WindowRenovationTemplates: WindowTemplate[] = [
  {
    material_name: WindowTemplateTypes.DoubleInsulated,
    u_value: 1.1,
    insulation_lambda: 0.6,
    cost_per_m2: 347.44,
    glazing_factor: -0.231,
  },
  {
    material_name: WindowTemplateTypes.TripleInsulated,
    u_value: 0.9,
    insulation_lambda: 0.5,
    cost_per_m2: 396.92,
    glazing_factor: -0.222,
  },
  {
    material_name: WindowTemplateTypes.DoubleInsulatedPassive,
    u_value: 0.7,
    insulation_lambda: 0.5,
    cost_per_m2: 553.66,
    glazing_factor: -0.257,
  },
];

export const enum DoorTemplateTypes {
  SFH_11 = 'Neue Eingangstür Einfamilienhaus, Ud-Wert 1,1 (W/m2K)',
  SFH_13 = 'Neue Eingangstür Einfamilienhaus, Ud-Wert 1,3 (W/m2K)',
  MFH_11 = 'Neue Eingangstür Mehrfamilienhaus, Ud-Wert 1,1 (W/m2K)',
  MFH_13 = 'Neue Eingangstür Mehrfamilienhaus, Ud-Wert 1,3 (W/m2K)',
}

export const DoorRenovationTemplates: DoorTemplate[] = [
  {
    material_name: DoorTemplateTypes.SFH_11,
    u_value: 1.1,
    cost_per_m2: 1204.2,
  },
  {
    material_name: DoorTemplateTypes.SFH_13,
    u_value: 1.3,
    cost_per_m2: 1204.2,
  },
  {
    material_name: DoorTemplateTypes.MFH_11,
    u_value: 1.1,
    cost_per_m2: 1026.89,
  },
  {
    material_name: DoorTemplateTypes.MFH_13,
    u_value: 1.3,
    cost_per_m2: 1026.89,
  },
];

const defaultMap: Record<NotWindowDoorEnvelopeType, { [key in insulation_method_enum]?: string }> = {
  [envelope_type_enum.FLAT_ROOF]: {
    [insulation_method_enum.CORE_INSULATION_BOARDS]: InsulationMaterialTemplates.Holzfaser.material_name,
    [insulation_method_enum.INSULATION_GRADIENT]: InsulationMaterialTemplates.PUR_Hartschaum.material_name,
  },
  [envelope_type_enum.BASEMENT_CEILING]: {
    [insulation_method_enum.INSULATION_BOARDS_WITH_CLADDING]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.INSULATION_BOARDS_WITHOUT_CLADDING]: InsulationMaterialTemplates.Mineralwolle.material_name,
  },
  [envelope_type_enum.PITCHED_ROOF]: {
    [insulation_method_enum.ABOVE_RAFTER]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.BETWEEN_RAFTER]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.BELOW_RAFTER]: InsulationMaterialTemplates.Mineralwolle.material_name,
  },
  [envelope_type_enum.TOP_FLOOR_CEILING]: {
    [insulation_method_enum.INSULATION_BOARDS_WALKABLE]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.INSULATION_BOARDS]: InsulationMaterialTemplates.Mineralwolle.material_name,
  },
  [envelope_type_enum.WALL]: {
    [insulation_method_enum.INTERIOR_PLASTER_SYSTEM]: InsulationMaterialTemplates.Mineralschaumplatten.material_name,
    [insulation_method_enum.FACING_SHELL]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.WDVS]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.VHF]: InsulationMaterialTemplates.Mineralwolle.material_name,
    [insulation_method_enum.CORE_INSULATION_BOARDS]: InsulationMaterialTemplates.Mineralwolle.material_name,
  },
  [envelope_type_enum.FLOOR]: {
    [insulation_method_enum.CORE_INSULATION_BOARDS]: InsulationMaterialTemplates.Mineralwolle.material_name,
  },
};

export function getEnvelopeInsulationDefaults(
  envelopeType: keyof typeof defaultMap,
  insulationMethod: insulation_method_enum,
): InsulationMaterial | undefined {
  const materialName = defaultMap[envelopeType][insulationMethod];
  return EnvelopeRenovationTemplates[envelopeType][insulationMethod]?.find(
    (template: InsulationMaterial) => template.material_name === materialName,
  );
}

export const EnvelopeRenovationTemplates: Record<
  NotWindowDoorEnvelopeType,
  { [key in insulation_method_enum]?: InsulationMaterial[] }
> = {
  [envelope_type_enum.FLAT_ROOF]: {
    [insulation_method_enum.CORE_INSULATION_BOARDS]: [
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum_Alukaschiert,
        cost_per_cm_m2: 1.4,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.14,
        insulation_lambda: 0.025,
      },
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 0.84,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.14,
        insulation_lambda: 0.025,
      },
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.09,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.16,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 0.68,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.18,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.94,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.2,
        insulation_lambda: 0.04,
      },
    ],
    [insulation_method_enum.INSULATION_GRADIENT]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.14,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.16,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 1.3,
        preparation_cost_per_m2: 87.51,
        insulation_thickness: 0.18,
        insulation_lambda: 0.025,
      },
    ],
  },
  [envelope_type_enum.PITCHED_ROOF]: {
    [insulation_method_enum.ABOVE_RAFTER]: [
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 1.85,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.028,
      },
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.68,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.032,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 2.06,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.16,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.27,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
    [insulation_method_enum.BETWEEN_RAFTER]: [
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 1.07,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.14,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.62,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
    [insulation_method_enum.BELOW_RAFTER]: [
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 1.85,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.028,
      },
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.68,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.032,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 2.06,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.12,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.27,
        preparation_cost_per_m2: 126.9,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
  },
  [envelope_type_enum.BASEMENT_CEILING]: {
    [insulation_method_enum.INSULATION_BOARDS_WITH_CLADDING]: [
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 2.18,
        preparation_cost_per_m2: 45.59,
        insulation_thickness: 0.1,
        insulation_lambda: 0.028,
      },
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 2.12,
        preparation_cost_per_m2: 45.59,
        insulation_thickness: 0.1,
        insulation_lambda: 0.04,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 2.47,
        preparation_cost_per_m2: 45.59,
        insulation_thickness: 0.12,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 3.12,
        preparation_cost_per_m2: 45.59,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
    [insulation_method_enum.INSULATION_BOARDS_WITHOUT_CLADDING]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.73,
        preparation_cost_per_m2: 25.84,
        insulation_thickness: 0.1,
        insulation_lambda: 0.04,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 2.61,
        preparation_cost_per_m2: 25.84,
        insulation_thickness: 0.08,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.23,
        preparation_cost_per_m2: 25.84,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
  },
  [envelope_type_enum.FLOOR]: {
    [insulation_method_enum.CORE_INSULATION_BOARDS]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 0.76,
        preparation_cost_per_m2: 22.18,
        insulation_thickness: 0.08,
        insulation_lambda: 0.037,
      },
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum_Alukaschiert,
        cost_per_cm_m2: 1.41,
        preparation_cost_per_m2: 3.71,
        insulation_thickness: 0.04,
        insulation_lambda: 0.024,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 1.25,
        preparation_cost_per_m2: 22.18,
        insulation_thickness: 0.08,
        insulation_lambda: 0.035,
      },
    ],
  },
  [envelope_type_enum.TOP_FLOOR_CEILING]: {
    [insulation_method_enum.INSULATION_BOARDS_WALKABLE]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 3.32,
        preparation_cost_per_m2: 23.55,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 2.79,
        preparation_cost_per_m2: 23.55,
        insulation_thickness: 0.18,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 3.57,
        preparation_cost_per_m2: 23.55,
        insulation_thickness: 0.1,
        insulation_lambda: 0.04,
      },
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 3.92,
        preparation_cost_per_m2: 23.55,
        insulation_thickness: 0.08,
        insulation_lambda: 0.024,
      },
    ],
    [insulation_method_enum.INSULATION_BOARDS]: [
      {
        ...InsulationMaterialTemplates.PUR_Hartschaum,
        cost_per_cm_m2: 2.64,
        preparation_cost_per_m2: 3.13,
        insulation_thickness: 0.08,
        insulation_lambda: 0.024,
      },
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 0.92,
        preparation_cost_per_m2: 3.13,
        insulation_thickness: 0.18,
        insulation_lambda: 0.04,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 0.67,
        preparation_cost_per_m2: 3.13,
        insulation_thickness: 0.16,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.92,
        preparation_cost_per_m2: 3.13,
        insulation_thickness: 0.08,
        insulation_lambda: 0.045,
      },
    ],
  },
  [envelope_type_enum.WALL]: {
    [insulation_method_enum.INTERIOR_PLASTER_SYSTEM]: [
      {
        ...InsulationMaterialTemplates.Mineralschaumplatten,
        cost_per_cm_m2: 3.84,
        preparation_cost_per_m2: 8.71,
        insulation_thickness: 0.1,
        insulation_lambda: 0.04,
      },
      {
        ...InsulationMaterialTemplates.Vakuumdaemmung,
        cost_per_cm_m2: 2.07,
        preparation_cost_per_m2: 13.87,
        insulation_thickness: 0.07,
        insulation_lambda: 0.024,
      },
    ],
    [insulation_method_enum.FACING_SHELL]: [
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 1.85,
        preparation_cost_per_m2: 13.87,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.56,
        preparation_cost_per_m2: 13.87,
        insulation_thickness: 0.1,
        insulation_lambda: 0.043,
      },
    ],
    [insulation_method_enum.WDVS]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 1.28,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 1.39,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.16,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.48,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.16,
        insulation_lambda: 0.04,
      },
    ],
    [insulation_method_enum.VHF]: [
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 1.39,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Holzfaser,
        cost_per_cm_m2: 2.48,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.1,
        insulation_lambda: 0.04,
      },
    ],
    [insulation_method_enum.CORE_INSULATION_BOARDS]: [
      {
        ...InsulationMaterialTemplates.EPS,
        cost_per_cm_m2: 0.89,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
      {
        ...InsulationMaterialTemplates.Mineralwolle,
        cost_per_cm_m2: 0.73,
        preparation_cost_per_m2: 81.41,
        insulation_thickness: 0.1,
        insulation_lambda: 0.035,
      },
    ],
  },
};

/**
 * Returns the glacing factor for a given window material name or undefined if not defined.
 *
 * Logs if the cost is not defined, so that it can be tracked down and eventually added to the lookup table.
 */
export const getGlazingFactor = (materialName: string): number | undefined => {
  const template = WindowRenovationTemplates.find((template) => template.material_name === materialName);
  if (!template) {
    console.error(`No window template found for material name ${materialName}.`);
    return;
  }

  const glazing_factor = template.glazing_factor;
  if (!isFinite(glazing_factor)) {
    console.error(`Glazing factor not defined for material name ${materialName}.`);
    return;
  }

  return glazing_factor;
};

/**
 * Returns the insulation preparation costs for the given parameters or undefined if not defined.
 *
 * Logs if the cost is not defined, so that it can be tracked down and eventually added to the lookup table.
 */
export const getInsulationPreparationCosts = (
  envelopeType: NotWindowDoorEnvelopeType,
  insulationMethod: insulation_method_enum,
  materialName: string,
): number | undefined => {
  const envelope = EnvelopeRenovationTemplates[envelopeType];
  if (!envelope) {
    console.error(`Envelope type ${envelopeType} not defined in envelopeRenovationParameters map.`);
    return;
  }

  const insulationMaterials = envelope[insulationMethod];
  if (!insulationMaterials || insulationMaterials.length === 0) {
    console.error(
      `Insulation method ${insulationMethod} not defined for envelope type ${envelopeType} in envelopeRenovationParameters map.`,
    );
    return;
  }

  const insulationMaterial = insulationMaterials.find((e) => e.material_name === materialName);
  if (!insulationMaterial) {
    console.error(
      `Insulation material name ${materialName} not defined for insulation method ${insulationMethod} and envelope type ${envelopeType} in envelopeRenovationParameters map.`,
    );
    return;
  }

  const preparationCostPerM2 = insulationMaterial.preparation_cost_per_m2;
  if (!preparationCostPerM2) {
    console.error(
      `preparation_cost_per_m2 is undefined for envelope type ${envelopeType}, insulation method ${insulationMethod}, and material name ${materialName}`,
    );
  }

  return preparationCostPerM2;
};
