import { Typography } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';

export type Equation = {
  type: 'CALCULATION_FORMULA' | 'APPROXIMATION_FORMULA';
  area: area_type_enum;
  affectedAreas: area_type_enum[];
  labels: {
    title: string;
    preBody?: ReactNode;
    body: ReactNode;
    postBody?: ReactNode;
  };
};

export const getNGFFormulaForCommercial = (t: TFunction<'translation'>): Equation => {
  return {
    type: 'CALCULATION_FORMULA',
    area: area_type_enum.NGF,
    affectedAreas: [area_type_enum.EBF],
    labels: {
      title: t('DataCollectionAreas_CalculatingFormulaTitle'),
      body: (
        <Typography>
          <Typography component="span" variant="body2">
            {' '}
            {t('DataCollectionAreas_NetFloorArea')} ={' '}
          </Typography>
          <Typography component="span" variant="body2" color={(theme) => theme.palette.areaColors.areas[0].textColor}>
            {' '}
            {t('DataCollectionAreas_EnergyReferenceArea')}{' '}
          </Typography>
        </Typography>
      ),
    },
  };
};

export const getNGFFormula = (t: TFunction<'translation'>): Equation => {
  return {
    type: 'CALCULATION_FORMULA',
    area: area_type_enum.NGF,
    affectedAreas: [area_type_enum.MF, area_type_enum.AF],
    labels: {
      title: t('DataCollectionAreas_CalculatingFormulaTitle'),
      body: (
        <Typography>
          <Typography component="span" variant="body2">
            {t('DataCollectionAreas_NetFloorArea')} ={' '}
          </Typography>
          <Typography component="span" variant="body2" color={(theme) => theme.palette.areaColors.areas[0].textColor}>
            {t('DataCollectionAreas_RentableArea')}
          </Typography>
          <Typography component="span" variant="body2">
            {' '}
            +{' '}
          </Typography>
          <Typography component="span" variant="body2" color={(theme) => theme.palette.areaColors.areas[1].textColor}>
            {t('DataCollectionAreas_CommonArea')}
          </Typography>
        </Typography>
      ),
    },
  };
};
