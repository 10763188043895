import {
  Card,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import Iconify from '../../../../../components/Iconify';

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    /* HACK: make the original popper component invisible so we replace it with a card */
    backgroundColor: 'transparent',
    color: 'transparent',
    padding: 0,
    margin: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
});

type Props = {
  title: ReactNode;
  subtitle?: ReactNode;
  body: ReactNode;
};

const InfoTooltip = ({ body, title, subtitle }: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener mouseEvent={open ? 'onClick' : false} onClickAway={handleClose}>
      <div>
        <WhiteTooltip
          open={open}
          onClose={handleClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="right"
          arrow
          title={
            <Card>
              <Stack spacing={1} p={2}>
                <Typography variant="subtitle2" fontWeight="bold">
                  {title}
                </Typography>
                {subtitle && (
                  <Typography variant="caption" color="text.primary">
                    {subtitle}
                  </Typography>
                )}
                <Stack spacing={1}>
                  <Typography variant="caption" color="text.secondary">
                    {body}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          }
        >
          <IconButton disableRipple sx={{ p: 0 }} onClick={handleOpen}>
            <Iconify icon={'material-symbols:info-outline'} width={14} height={14} />
          </IconButton>
        </WhiteTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default InfoTooltip;
