import { Box, Grid, MenuItem, useTheme } from '@mui/material';
import { energy_consumer_technology_type_enum } from '@predium/client-graphql';
import { translateEnergyConsumerTechnologyTypeEnum, translateEnergySourceTypeEnum } from '@predium/i18n/client';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RHFDatePicker, RHFNumberField, RHFSelect } from '../../../../../../../components/hook-form';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../../Components/ActionCustomStyles';

export default function TechnicalCommonFields() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getValues } = useFormContext();

  const consumerTechType = getValues('consumer_technology_type');
  const technologyName = getValues('technology_name');
  const energySourceType = getValues('energy_source_type');
  const costPerM2 = getValues('cost_per_m2');

  return (
    <>
      <RHFSelect
        name="consumer_technology_type"
        label={t('General_Selection')}
        size={'small'}
        sx={{ ...SelectSx, mb: 3 }}
        disabled
      >
        <MenuItem value={consumerTechType}>
          <StyledListItemText
            action={translateEnergyConsumerTechnologyTypeEnum(
              consumerTechType as energy_consumer_technology_type_enum,
              2,
            )}
          />
        </MenuItem>
      </RHFSelect>

      <Box
        sx={{
          p: 2,
          border: `1px solid ${theme.palette.grey[500_32]}`,
          borderRadius: '8px',
          mb: 3,
        }}
      >
        <RHFSelect
          name="technology_name"
          label={t('General_Technology')}
          size={'small'}
          sx={{ ...SelectSx, mb: 3 }}
          disabled
        >
          <MenuItem value={technologyName}>
            <StyledListItemText action={technologyName} secondary={`${costPerM2 ? '€' : ''}`} />
          </MenuItem>
        </RHFSelect>

        <Grid container spacing={2}>
          <Grid item xs={6} mb={2}>
            <RHFNumberField
              name="efficiency"
              label={t('General_ExpenditureFactor')}
              size={'small'}
              sx={NumberInputSx}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            {!!costPerM2 && (
              <RHFNumberField
                name="cost_per_m2"
                label={t('General_PricePerM2AreaUsable')}
                size={'small'}
                sx={NumberInputSx}
                disabled
              />
            )}
          </Grid>
        </Grid>

        <RHFSelect name="energy_source_type" label={t('General_EnergySource')} size={'small'} sx={SelectSx} disabled>
          <MenuItem value={energySourceType}>
            <StyledListItemText action={translateEnergySourceTypeEnum(energySourceType)} />
          </MenuItem>
        </RHFSelect>
      </Box>
      <RHFDatePicker
        label={t('General_PlannedFor')}
        name="implementation_to"
        size={'small'}
        sx={NumberInputSx}
        disabled
      />
    </>
  );
}
